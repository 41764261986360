import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/link/link.js';

import { css, html, LitElement } from 'lit';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../mixins/nova-nav/nova-nav.js';

class RegistrationCallToAction extends LocalizeNova(NovaNavMixin(LitElement)) {

  static get properties() {
    return {
      // both props need to be provided for the component to link to the application
      // otherwise it will link to the generic view-applications page with generic text
      activityTitle: { type: String }, // optional - title of activity for application registration
      applicationUuid: { type: String }, // optional - uuid of application, to link to it
    };
  }

  static get styles() {
    return [
      css`
        d2l-alert {
          max-width: 100%;
        }

        .alert-content {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          justify-content: space-between;
        }
`,
    ];
  }

  get _alertText() {
    if (this.applicationUuid && this.activityTitle) {
      return this.localize('registration-call-to-action.viewApplication', {
        strong: this.l10nTags.strong(),
        activityTitle: this.activityTitle,
      });
    }
    return this.localize('registration-call-to-action.viewApplications', { strong: this.l10nTags.strong() });
  }

  // The d2l-alert has a button, but we want the action to navigate to a new page.
  // So a link is being used for accessibility, i.e. so the screen-reader knows it's a link.
  get _link() {
    const href = this.applicationUuid && this.activityTitle
      ? `/requests/${this.applicationUuid}`
      : '/requests';
    return html`<d2l-link href=${href}>${this.localize('registration-call-to-action.alertButtonText')}</d2l-link>`;
  }

  render() {
    return html`
      <d2l-alert type="warning">
        <div class="alert-content">
          <span>${this._alertText}</span>
          <span>${this._link}</span>
        </div>
      </d2l-alert>
    `;
  }
}

window.customElements.define('registration-call-to-action', RegistrationCallToAction);
