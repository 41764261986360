import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/form/form.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { until } from 'lit/directives/until.js';

import LoginMagicLink from '../../components/login/login-magic-link/login-magic-link.js';
import LoginMarketing from '../../components/login/login-marketing/login-marketing.js';
import LoginMulti from '../../components/login/login-multi/login-multi.js';
import LoginSaml from '../../components/login/login-saml/login-saml.js';

import { novaCardStyles } from '../../components/general/nova-card/nova-card-styles.js';
import { NovaNavMixin } from '../../mixins/nova-nav/nova-nav.js';

export default class LoginView extends RequesterMixin(NovaNavMixin(LitElement)) {

  static get properties() {
    return {
      _showcase: { type: Boolean, attribute: '_showcase', reflect: true },
    };
  }

  static get styles() {
    return [
      novaCardStyles,
      css`
        :host {
          display: block;
          height: 100vh;
          overflow-y: auto;
        }

        .splash {
          background: #f7fafe;
          background-image: url(../../../assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
          border-top: solid 6px var(--d2l-branding-primary-color);
          box-sizing: border-box;
          display: grid;
          min-height: 100%;
          padding: 1rem;
          place-items: center;
          width: 100%;
        }

`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get loggedIn() {
    return this.session.loggedIn && !this.session.isPublicPortalGuest;
  }

  async firstUpdated() {
    if (this.loggedIn) this.navigateWithoutHistory('/');
  }

  render() {
    return html`
      <div class="splash">
        ${until(this._getLoginContent())}
      </div>
    `;
  }

  async _getLoginContent() {
    if (this.loggedIn) return nothing;
    const { loginType, publicPortalEnabled } = this.session.appDetails;
    if (this.session.hasFeature('marketing')) {
      return new LoginMarketing();
    } else if (loginType === 'saml' && (this.session.isPublicPortalGuest)) {
      return new LoginSaml();
    } else if (loginType === 'saml' && publicPortalEnabled) {
      const loginLink = await this.client.createGuestLogin({ relayState: '/' });
      const url = new URL(loginLink.magicLink);
      const pathAndParams = `${url.pathname}${url.search}`;
      this.navigateWithoutHistory(pathAndParams);
    } else if (loginType === 'saml') {
      return new LoginSaml();
    } else if (loginType === 'magic-link') {
      return new LoginMagicLink();
    } else if (loginType === 'multi') {
      return new LoginMulti();
    }
  }

}

window.customElements.define('view-login', LoginView);
