import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/meter/meter-circle.js';
import '@brightspace-ui/core/components/paging/pager-load-more.js';

import '../../../components/onboarding/checkbox-skill-category-card/checkbox-skill-category-card.js';
import '../../../../shared/components/skills/area-of-interest/area-of-interest-chip.js';
import '../../../../shared/components/general/no-results/no-results.js';
import '../../../../shared/components/skills/skill-chip-list/skill-chip-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../../shared/methods.js';

export default class OnboardingPersonalizationFlow extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      jobTitleId: { type: String },
      jobTitleName: { type: String },
      selectionForProfile: { type: Object },
      _step: { type: String },
      _relatedJobTitlesData: { type: Array },
      _skillSubcategoryData: { type: Array },
      _showAllSkillCategories: { state: true },
      _doneLoadingSkillCategories: { state: true },
      _doneLoadingRelatedTitles: { state: true },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      css`
        :host {
          display: block;
          padding-top: 1.5rem;
        }

        .onboarding-screens {
          display: flex;
          flex-direction: column;
        }

        .template-wrapper {
          margin-bottom: 30px;
        }

        .header-block {
          display: flex;
        }

        .title {
          font-family: 'Lato', sans-serif;
          font-size: 40px;
          font-weight: 400;
          margin-bottom: 0;
        }

        .sub-title {
          font-family: 'Lato', sans-serif;
          font-size: 19px;
          font-weight: 400;
          margin-top: 0;
        }

        .header {
          font-family: 'Lato', sans-serif;
          font-size: 30px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 0;
        }

        .sub-header {
          color: var(--d2l-color-galena);
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          margin-top: 10px;
        }

        .loading-spinner-container {
          display: flex;
          height: 385px;
        }

        .loading-spinner {
          margin: auto;
        }

        .job-title {
          background-color: var(--d2l-color-celestine-plus-2);
          font-weight: 700;
          padding: 0 6px;
        }

        .skill-tiles-list {
          display: grid;
          grid-auto-rows: 1fr;
          grid-column-gap: 40px;
          grid-row-gap: 20px;
          grid-template-columns: 1fr 1fr;
          margin-top: 36px;
        }

        .item-wrapper {
          border: 1px solid var(--d2l-color-mica);
          border-radius: 8px;
          padding: 18px;
        }

        .item-wrapper:hover {
          border-color: var(--d2l-color-celestine);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .item-wrapper:hover .checkbox {
          border-color: var(--d2l-color-celestine);
          border-width: 2px;
          outline-width: 0;
        }

        .selected {
          background-color: rgb(243, 251, 255);
          border-color: rgb(182, 203, 232);
        }

        .list-item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .header-row {
          display: flex;
          justify-content: space-between;
        }

        .img-wrapper {
          display: flex;
          justify-content: space-around;
          margin-right: 30px;
        }

        .category-label {
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          font-weight: 700;
        }

        .no-margin {
          margin: 0;
        }

        .role-skillsets-container {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin-top: 36px;
          min-height: 90px;
        }

        .empty-result-box {
          border: 1px solid #cdd5dc;
          border-radius: 6px;
          padding: 24px 0 24px 30px;
        }

        .onboarding-footer {
          box-shadow: 0 0 4px 0 #00000033;
          display: flex;
          padding: 12px 60px;
        }

        .search-wrapper {
          margin-top: 36px;
          width: 50%;
        }

        .missing-results {
          color: var(--d2l-color-ferrite);
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .title-sub-header {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          margin: 24px 0;
        }

        .sub-section-wrapper {
          display: grid;
          grid-template-columns: 1fr 2.5fr;
        }

        .meter-circle-wrapper {
          display: flex;
        }

        .meter-circle {
          width: 90%;
        }

        .job-overlap-section-header {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          margin-bottom: 10px;
        }

        .second-header {
          margin-top: 24px;
        }

        @media (max-width: 768px) {
          .header-block {
            display: block;
          }

          .header {
            font-family: 'Lato', sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 0;
            margin-top: 0;
          }

          .skill-tiles-list {
            display: grid;
            grid-auto-rows: auto;
            grid-row-gap: 20px;
            grid-template-columns: 1fr;
            margin-bottom: 80px;
          }
        }

        @media (max-width: 480px) {
          .sub-section-wrapper {
            display: block;
          }

          .meter-circle {
            margin: auto;
            width: 60%;
          }
        }

`,
    ];
  }

  constructor() {
    super();
    this._step = '1';
    this._doneLoadingRelatedTitles = false;
    this._careerInterestAreaMap = {};
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this.SKILL_SUBCATEGORY_LIMIT = 6;
    this.NUM_SKILLS_TO_DISPLAY = window.innerWidth <= 767 ? 3 : 5;
    this.MIN_SKILL_OVERLAP = 10;
    this.NUM_SKILLS_FOR_SCALE_DOWN = 40;

    if (!this._skillSubcategoryData) {
      this._fetchSkillSubcategoryData();
    }
    if (!this._relatedJobTitlesData) {
      this._fetchRelatedRolesData(this.jobTitleId);
    }
  }

  updated() {
    super.updated();
    if (this._skillSubcategoryData) {
      this._doneLoadingSkillCategories = true;
    }
    if (this._relatedJobTitlesData && !this.searchterm) {
      this._doneLoadingRelatedTitles = true;
    }
  }

  async _fetchSkillSubcategoryData() {
    this._skillSubcategoryData = await this.client.getSkillSubcategoriesForTitle(this.jobTitleId, this.session.tenant.id, this.SKILL_SUBCATEGORY_LIMIT);
    const hydratedCategories = this.selectionForProfile.skillCategories.map(category => {
      const skills = this._skillSubcategoryData.filter(cat => cat.skillCategoryId === category.skillCategoryId);
      return { ...category, skills: skills.skills };
    });
    this._skillSubcategoryMap = mapify([...this._skillSubcategoryData, ...hydratedCategories], 'skillCategoryId');
  }

  async _fetchRelatedRolesData(titleId) {
    const allSimilarTitles = await this.client.getSimilarTitlesBySkillOverlap(this.session.tenant.id, titleId);
    this._relatedJobTitlesData = allSimilarTitles.filter(data => data.skillOverlapPercentage >= this.MIN_SKILL_OVERLAP);
    this._relatedJobTitlesMap = mapify(this._relatedJobTitlesData, 'jobId');
    this._defaultJobTitlesData = this._relatedJobTitlesData;
    this._defaultJobTitlesMap = this._relatedJobTitlesMap;
    this._careerInterestAreaMap = { ...this._relatedJobTitlesMap, ...this.selectionForProfile.titles };
  }

  _skillCategoryListItem(skillCategoryData) {
    const { skillCategoryId, skillCategoryName, skills } = skillCategoryData;
    const selected = this.selectionForProfile.skillCategories.map(category => category.skillCategoryId).includes(skillCategoryId);
    const stylizedSkills = skills.map(skill => {
      return { ...skill, isRelatedToRole: !selected, isInSkillProfile: !!selected };
    });
    return html`
      <checkbox-skill-category-card
        .cardId=${skillCategoryId}
        .checkboxLabel=${skillCategoryName}
        .selected=${selected}
        @card-selected=${this.skillSetSelected}>
        <skill-chip-list slot="sub-section" max-skills="${this.NUM_SKILLS_TO_DISPLAY || 3}" truncate-skill-text .skills=${stylizedSkills}></skill-chip-list>
      </checkbox-skill-category-card>
    `;
  }

  skillSetSelected(e) {
    const key = e.detail.id;
    const skillSet = this._skillSubcategoryMap[key];
    if (!this.selectionForProfile.skillCategories.map(category => category.skillCategoryId).includes(key)) {
      this.selectionForProfile.skillCategories.push(skillSet);
    } else {
      this.selectionForProfile.skillCategories.splice(this.selectionForProfile.skillCategories.map(category => category.skillCategoryId).indexOf(key), 1);
    }
    this.requestUpdate();
  }

  get _skillCategoryList() {
    const listItems = this._skillSubcategoryData.map(data => this._skillCategoryListItem(data));
    return html`
      <div class="skill-tiles-list">
        ${listItems}
      </div>
    `;
  }

  _otherJobTitleListItem(jobTitleData) {
    const { jobId, jobName, skillOverlapPercentage, overlappingSkills, otherSkills, titleSOC } = jobTitleData;
    const selected = this.selectionForProfile.titles.map(title => title.jobId).includes(jobId);
    const overlappingCountScaledDown = Math.floor(this.NUM_SKILLS_FOR_SCALE_DOWN * skillOverlapPercentage / 100);
    const stylizedOverlappingSkills = overlappingSkills.slice(0, overlappingCountScaledDown).map(skill => {
      return { name: skill.name, isRelatedToRole: !selected, isInSkillProfile: !!selected };
    });
    const otherCountScaledDown = this.NUM_SKILLS_FOR_SCALE_DOWN - overlappingCountScaledDown;
    const stylizedOtherSkills = otherSkills.slice(0, otherCountScaledDown).map(skill => {
      return { name: skill.name, isRelatedToRole: false, isInSkillProfile: !!selected };
    });
    return html`
      <checkbox-skill-category-card
        .cardId=${jobId}
        .checkboxLabel=${jobName}
        .checkboxSubLabel=${titleSOC}
        .selected=${selected}
        @card-selected=${this.jobSelected}>
        <div slot="sub-section">
          <div class="sub-section-wrapper">
            <div class="meter-circle-wrapper">
              <d2l-meter-circle class="meter-circle" max=100 percent value=${skillOverlapPercentage} text="${this.localize('onboarding-personalization-flow.titleSelection.meterText')}"></d2l-meter-circle>
            </div>
            <div>
              <div class="job-overlap-section-header">${this.localize('onboarding-personalization-flow.titleSelection.transferrableSkills')}</div>
              <skill-chip-list max-skills="2" stop-wrap truncate-skill-text .skills=${stylizedOverlappingSkills}></skill-chip-list>
              ${stylizedOtherSkills.length > 0 ? html`
              <div class="job-overlap-section-header second-header">${this.localize('onboarding-personalization-flow.titleSelection.growthOpportunities')}</div>
              <skill-chip-list max-skills="2" stop-wrap truncate-skill-text .skills=${stylizedOtherSkills}></skill-chip-list>` : nothing}
            </div>
          </div>
        </div>
      </checkbox-skill-category-card>
    `;
  }

  async _searchChange(e) {
    this.searchterm = e?.detail.value.trim();
    this._doneLoadingRelatedTitles = false;
    this.requestUpdate();
    if (this.searchterm.length > 0) {
      const allSimilarTitles = await this.client.getSearchTermTitlesBySkillOverlap(this.session.tenant.id, this.jobTitleId, this.searchterm);
      this._relatedJobTitlesData = allSimilarTitles.filter(data => data.skillOverlapPercentage >= this.MIN_SKILL_OVERLAP);
      this._relatedJobTitlesMap = mapify(this._relatedJobTitlesData, 'jobId');
      this._doneLoadingRelatedTitles = true;
    } else {
      this.searchterm = undefined;
      this._relatedJobTitlesData = this._defaultJobTitlesData;
      this._relatedJobTitlesMap = this._defaultJobTitlesMap;
      this._doneLoadingRelatedTitles = true;
    }
    this.requestUpdate();
  }

  jobSelected(e) {
    const key = e.detail.id;
    const title = this._relatedJobTitlesMap[key];
    if (!this.selectionForProfile.titles.map(t => t.jobId).includes(key)) {
      this.selectionForProfile.titles.push(title);
    } else {
      this.selectionForProfile.titles.splice(this.selectionForProfile.titles.map(t => t.jobId).indexOf(key), 1);
    }
    this.requestUpdate();
  }

  get _otherJobTitleList() {
    if (this._relatedJobTitlesData.length === 0 && this.searchterm !== '') {
      return html`
      <no-results>
        ${this.localize('view-activity.noResults.prompt.1')}
        <div class="missing-results">${this.searchterm}</div>
      </no-results>`;
    }

    if (this._relatedJobTitlesData.length === 0) {
      return html`
        <div class="empty-result-box">
          ${this.localize('onboarding-personalization-flow.titleSelection.empty')}
        </div>`;
    }

    const listItemsToShow = this._relatedJobTitlesData.map(data => this._otherJobTitleListItem(data)).slice(0, this._titleAmountToShow);

    return html`
      <div class="skill-tiles-list">
        ${listItemsToShow}
      </div>
    `;
  }

  goBackToGetStarted() {
    const goToGetStartedEvent = new CustomEvent('go-to-get-started', {
      detail: { skillCategories: this.selectionForProfile.skillCategories, titles: this.selectionForProfile.titles },
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(goToGetStartedEvent);
  }

  goToRelatedTitleSelection() {
    this._step = '2';
  }

  goToGenerateResults() {
    const selectedSkills = [];

    const selectedSkillSubcategories = this.selectionForProfile.skillCategories.map(category => {
      if (category.skills) {
        category.skills.map(skill => {
          selectedSkills.push(skill);
        });
      }
      return { skillCategoryId: category.skillCategoryId, skillCategoryName: category.skillCategoryName };
    });

    const selectedOtherTitles = this.selectionForProfile.titles.map(job => {
      return { id: job.jobId, name: job.jobName };
    });

    const goToGeneratingResultsEvent = new CustomEvent('go-to-generating-results', {
      detail: {
        selectedSkillSubcategories,
        selectedSkills,
        selectedOtherTitles,
      },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(goToGeneratingResultsEvent);
  }

  goBackToSkillSelection() {
    this._step = '1';
  }

  handleSkillSetSelected(category) {
    return () => {
      this.skillSetSelected({ detail: { id: category.skillCategoryId } });
    };
  }

  handleJobSelected(title) {
    return () => {
      this.jobSelected({ detail: { id: title.jobId } });
    };
  }

  get _loadingSpinner() {
    return html`
      <div class="loading-spinner-container">
        <d2l-loading-spinner class="loading-spinner" size="50"></d2l-loading-spinner>
      </div>
    `;
  }

  get areasOfInterestTemplate() {
    const stylizedSkillCategories = this.selectionForProfile.skillCategories.map(category => {
      const htmlString = html`<area-of-interest-chip removable .text=${ category.skillCategoryName } .titleText=${ category.skillCategoryName } @nova-chip-remove=${this.handleSkillSetSelected(category)}></area-of-interest-chip>`;
      return { id: category.skillCategoryId, html: htmlString };
    });

    const stylizedCareers = this.selectionForProfile.titles.map(title => {
      const htmlString = html`<area-of-interest-chip removable icon="career" .text=${ title.jobName } .titleText=${ title.jobName } @nova-chip-remove=${this.handleJobSelected({ detail: { id: title.jobId } })}></area-of-interest-chip>`;
      return { id: title, html: htmlString };
    });

    this._areaOfInterests = [...stylizedSkillCategories, ...stylizedCareers];

    return html`
    ${this._areaOfInterests.length > 0 ? html`
    <div class="role-skillsets-container d2l-skeletize">
      <p class="d2l-body-standard no-margin">
        ${this.localize('view-landing-page.profile.description.jobTitle', { jobTitle: html`<span class="job-title">${this.jobTitleName}.</span>` })}
        ${this.localize('view-landing-page.profile.description.interests')}
      </p>
      ${repeat(this._areaOfInterests,
    area => area.id,
    area => area.html
  )}
    </div>` : html`
    <div class="role-skillsets-container d2l-skeletize">
      <p class="d2l-body-standard no-margin">
        ${this.localize('view-landing-page.profile.description.jobTitle', { jobTitle: html`<span class="job-title">${this.jobTitleName}.</span>` })}
        ${this._step === '1' ? this.localize('view-landing-page.profile.description.interestsPlaceholder') : nothing}
      </p>
    </div>`}`;
  }

  get skillSelectionTemplate() {
    return html`
    <div class="template-wrapper">
      <div class="header-block">
        <div class="img-wrapper">
          <img aria-hidden="true" src="assets/img/onboarding_skillset_selection.svg">
        </div>
        <div>
          <h1 class="title d2l-heading-1">${this.localize('onboarding-personalization-flow.skillSelection.title')}</h1>
          <h2 class="sub-title d2l-heading-2">${this.localize('onboarding-personalization-flow.skillSelection.subtitle')}</h2>
        </div>
      </div>
      ${this.areasOfInterestTemplate}
      ${this._doneLoadingSkillCategories ? this._skillCategoryList : this._loadingSpinner}
    </div>
    <d2l-floating-buttons>
      <d2l-button primary @click=${this.goToRelatedTitleSelection} ?disabled=${this.selectionForProfile.skillCategories.length === 0}>${this.localize('onboarding-personalization-flow.skillSelection.nextButton')}</d2l-button>
      ${this.session.needsOnboarding ? html`
        <d2l-button @click=${this.goBackToGetStarted}>${this.localize('onboarding-personalization-flow.skillSelection.backButton')}</d2l-button>
      ` : nothing}
      <d2l-button-subtle @click=${this.goToRelatedTitleSelection} text="${this.localize('onboarding-personalization-flow.skillSelection.skipButton')}"></d2l-button-subtle>
    </d2l-floating-buttons>`;
  }

  get relatedTitlesSelectionTemplate() {
    return html`
    <div class="template-wrapper">
      <div class="header-block">
        <div class="img-wrapper">
          <img aria-hidden="true" src="assets/img/onboarding_title_selection.svg">
        </div>
        <div>
          <h1 class="title d2l-heading-1">${this.localize('onboarding-personalization-flow.titleSelection.title')}</h1>
          <h2 class="sub-title d2l-heading-2">${this.localize('onboarding-personalization-flow.titleSelection.subtitle')}</h2>
        </div>
      </div>
      ${this.areasOfInterestTemplate}
      <div class="search-wrapper">
        <d2l-input-search
          id="search"
          label="${this.localize('onboarding-personalization-flow.titleSelection.search')}"
          @d2l-input-search-searched=${this._searchChange}
          placeholder="${this.localize('onboarding-personalization-flow.titleSelection.search')}">
        </d2l-input-search>
      </div>
      <div class="title-sub-header">${this.localize('onboarding-personalization-flow.titleSelection.relatedCareers', { jobTitle: html`<span class="job-title">${this.jobTitleName}</span>` })}</div>
      ${this._doneLoadingRelatedTitles ? this._otherJobTitleList : this._loadingSpinner}
    </div>
    <d2l-floating-buttons>
      <d2l-button primary @click=${this.goToGenerateResults} ?disabled=${this.selectionForProfile.titles.length === 0}>${this.localize('onboarding-personalization-flow.titleSelection.nextButton')}</d2l-button>
      <d2l-button @click=${this.goBackToSkillSelection}>${this.localize('onboarding-personalization-flow.skillSelection.backButton')}</d2l-button>
    </d2l-floating-buttons>`;
  }

  render() {
    return html`
      <div class="onboarding-screens">
        ${this._step === '1' ? this.skillSelectionTemplate : this.relatedTitlesSelectionTemplate}
      </div>
    `;
  }
}

window.customElements.define('onboarding-personalization-flow', OnboardingPersonalizationFlow);
