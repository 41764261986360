import { Editor } from '@tiptap/core';
import { FontFamily } from '@tiptap/extension-font-family';
import Link from '@tiptap/extension-link';
import { StarterKit } from '@tiptap/starter-kit';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';

import {
  defaultFontFamilyId,
  defaultFontSizeId,
  editorMarkIds,
  editorNodeIds,
  fontFamilyIds,
  fontSizeIds,
  headingIds,
  textAlignIds
} from '../constants.js';
import FontSize from '../extensions/FontSize.js';

const getSuffix = (text, prefix) => text.substring(prefix.length).toLocaleLowerCase();
export const getActiveFormating = editor => {
  const activeFontFamily = fontFamilyIds.find(id => editor.isActive({ fontFamily: getSuffix(id, 'fontFamily') })) || defaultFontFamilyId;
  const activeFontSize = fontSizeIds.find(id => editor.isActive({ fontSize: getSuffix(id, 'fontSize') })) || defaultFontSizeId;
  const activeHeading = headingIds.find(id => editor.isActive('heading', { level: parseInt(getSuffix(id, 'heading')) }));
  const activeTextAlign = textAlignIds.find(id => editor.isActive({ textAlign: getSuffix(id, 'textAlign') }));

  return [...editorMarkIds, ...editorNodeIds]
    .filter(id => editor.isActive(id))
    .concat(activeFontFamily, activeFontSize, activeHeading, activeTextAlign)
    .filter(x => x);
};

export const getEditor = (container, content) => {
  return new Editor({
    element: container,
    extensions: [
      FontSize,
      FontFamily,
      Link.configure({
        defaultProtocol: 'https',
        HTMLAttributes: {
          style: 'color: var(--d2l-color-celestine, #006fbf); cursor: pointer; text-decoration: none;',
        },
        openOnClick: false,
        protocols: [{ scheme: 'mailto', optionalSlashes: true }],
      }),
      StarterKit,
      Subscript,
      Superscript,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      Underline,
    ],
    content,
  });
};
