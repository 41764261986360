import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';

import '../../../shared/components/custom-content/home-custom-content/home-custom-content.js';
import '../../../shared/components/custom-content/faqs/faqs.js';

import { bodyStandardStyles, heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { animate, AnimateController, fadeIn, fadeOut } from '@lit-labs/motion';
import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';

import { novaCardStyles } from '../../components/general/nova-card/nova-card-styles.js';
import { spaNav } from '../../../../shared/methods.js';

export default class ViewHome extends SkeletonMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      tenant: { type: Object },
      _providerLogos: { type: Array, attribute: false },
      _startIndexOfLogos: { type: Number },
      _logoLimit: { type: Number },
      _numberOfLogosInCol: { type: Number, attribute: false },
    };
  }
  animationController = new AnimateController(this, {
    defaultOptions: {
      keyframeOptions: {
        duration: 20,
        fill: 'forwards',
      },
      properties: ['opacity'],
      in: fadeIn,
      out: fadeOut,
    },
  });

  static get styles() {
    return [
      super.styles,
      bodyStandardStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      novaCardStyles,
      css`
        :host {
          display: block;
        }

        .d2l-centerer {
          margin: 0 auto;
          max-width: 1230px;
        }

        .d2l-gutters {
          padding-left: 3.439%;
          padding-right: 3.439%;
          position: relative;
        }

        /* Overview Template */
        .overview-container-wide {
          margin: 2rem 0;
        }

        .overview-container-narrow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .overview-content {
          flex: 0 0 60%;
          padding: 2rem 0;
        }

        .overview-title {
          font-size: 3.1rem;
          font-weight: 900;
          letter-spacing: -0.05rem;
          line-height: 4rem;
        }

        .overview-text {
          font-weight: normal;
        }

        .overview-img {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          flex: 0 2 40%;
        }

        /* Academic Partners Template */
        .academic-partners-wide {
          background-color: var(--d2l-color-regolith);
          padding: 50px 0;
        }

        .academic-partners-narrow {
          display: flex;
          flex-direction: column;
        }

        .academic-title {
          font-weight: bold;
          margin: 0;
          text-align: center;
        }

        .academic-body {
          margin-top: 15px;
          padding-bottom: 35px;
          text-align: center;
        }

        .academic-partners-login {
          padding-top: 35px;
          text-align: center;
        }

        .logo-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
        }

        .logo-container {
          align-items: center;
          display: flex;
          flex: auto;
          height: 90px;
          justify-content: center;
          margin: auto;
          width: 220px;
        }

        .logo-img {
          display: block;
          height: auto;
          max-height: 90px;
          max-width: 220px;
          object-fit: contain;
          width: 100%;
        }

        /* Help Template */
        .help-wide {
          background-color: var(--d2l-color-regolith);
          margin-top: 120px;
          padding: 3.5rem 0;
        }

        .help-narrow {
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        .help-img {
          height: 110px;
          width: auto;
        }

        .help-title {
          font-weight: bold;
          margin-bottom: 0;
          text-align: center;
        }

        .help-text {
          text-align: center;
        }

        .brightspace-bttn {
          margin-top: 6px;
        }

        .help-button {
          margin-top: 1.1rem;
          width: fit-content;
        }

        .prev-page {
          left: 0; /* Component width + margin */
        }

        .next-page {
          right: 0; /* Component width + margin */
        }

        .carousel-container {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 360px;
          justify-content: center;
          padding-top: 52px;
          row-gap: 1.5rem;
        }

        .button-container {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 42px;
        }

        @media (max-width: 1280px) {
          .next-page {
            right: 2rem;
          }

          .prev-page {
            left: 2rem;
          }

          .d2l-gutters {
            padding-left: 15px;
            padding-right: 15px;
          }

          .carousel-container {
            display: flex;
            height: 360px;
            justify-content: center;
            margin: 0 10%;
            padding-top: 52px;
            row-gap: 1.5rem;
          }

          .logo-container {
            align-items: center;
            display: flex;
            flex: auto;
            height: 90px;
            justify-content: center;
            margin: auto;
            width: 220px;
          }

          .logo-row {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
          }

          .logo-row-min {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 1.5rem;
            width: 100%;
          }

          .logo-container-min {
            align-items: center;
            display: flex;
            flex: auto;
            height: 90px;
            justify-content: center;
            margin: auto;
            width: 100%;
          }

          .overview-title {
            font-size: revert;
            letter-spacing: -0.03rem;
            line-height: 2rem;
          }

          .overview-container-wide {
            margin: 0;
          }

          .help-wide {
            margin-top: 4rem;
          }
        }

        @media (max-width: 767px) {
          .academic-partners-wide {
            background-color: var(--d2l-color-regolith);
            padding: 70px 0;
          }

          .academic-partners-login {
            padding-top: 40px;
          }

          .academic-body {
            margin-top: 10px;
            padding-bottom: 40px;
          }

          .overview-content {
            padding: 70px 0;
          }
        }
`,
    ];
  }

  get _endIndexOfLogos() {
    return this._startIndexOfLogos + this._logoLimit;
  }

  constructor() {
    super();
    this.homePageContent = {};
    this.faqs = {};
    this._providerLogos = [];
    this.skeleton = true;
    this._startIndexOfLogos = 0;
    this._setLogoCount(window.innerWidth);
    this._handleWindowResize = this._handleWindowResize.bind(this);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    for (const [prop] of _changedProperties) {
      if (prop === '_logoLimit') {
        this._startIndexOfLogos = 0;
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    window.addEventListener('resize', this._handleWindowResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._handleWindowResize);
  }

  async firstUpdated() {
    if (this.session.tenantId) {
      const promises = [
        this.client.getCustomHomepageContent(this.session.tenantId),
        this.client.getCustomFAQContent(this.session.tenantId),
        this.client.getVisibleProviders(this.session.tenantId),
      ];
      [this.homePageContent, this.faqs, this.visibleProviders] = await Promise.all(promises);

      this._providerLogos = this._shuffle(this.visibleProviders);
    }
    this.skeleton = false;
  }

  _handleWindowResize() {
    this._setLogoCount(window.innerWidth);
  }

  get _hasBrightspaceInstance() {
    return !!this.session.tenant.hasTag('brightspaceInstance');
  }

  get _brightspaceNavButton() {
    const brightspaceName = this.session.tenant.brightspaceName;
    return html`
    <d2l-button
      class="brightspace-bttn"
      @click=${this.toBrightspaceURL}>
      ${this.localize('home.brightspace.button', { BrightspaceName: brightspaceName })}
    </d2l-button>
    `;
  }

  toBrightspaceURL() {
    const brightspaceURL = this.session.tenant.brightspaceURL;
    window.location = brightspaceURL;
  }

  toPrograms = () => {
    this.navigate('/activities');
  };

  /**
  * @description Moves the carousel to the next page
  */
  nextPage() {
    this._startIndexOfLogos = this._endIndexOfLogos;
  }

  /**
   * @description Moves the carousel to the previous page
   */
  prevPage() {
    this._startIndexOfLogos -= this._logoLimit;
  }

  _setLogoCount(windowWidth) {
    const _oldVal = this._logoLimit;
    if (windowWidth <= 767) {
      this._numberOfLogosInCol = 1;
      this._logoLimit = 4;
    } else if (windowWidth <= 1280) {
      this._numberOfLogosInCol = 2;
      this._logoLimit = 8;
    } else {
      this._numberOfLogosInCol = 4;
      this._logoLimit = 12;
    }

    if (_oldVal !== this._logoLimit) {
      this.style.setProperty('--LogosPerRow', this._numberOfLogosInCol);
      this.requestUpdate();
    }
  }
  get _nextButtonTemplate() {
    return html`
      <d2l-button-icon
        ?disabled=${!(this._endIndexOfLogos < this._providerLogos.length)}
        aria-label=${this.localize('view-home.carousel.next')}
        @click=${this.nextPage}
        icon="tier3:chevron-right"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>`;
  }

  get _prevButtonTemplate() {
    return html`
      <d2l-button-icon
        ?disabled=${!(this._startIndexOfLogos > 0)}
        aria-label=${this.localize('view-home.carousel.prev')}
        @click=${this.prevPage}
        icon="tier3:chevron-left"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>`;
  }

  render() {
    const defaultImage = this.session.tenant?.hasFeature('marketing') ? 'd2l-wave-mouse-lightbulb-green.png' : 'd2l-wave-mouse-lightbulb.png';
    const overview = this.homePageContent?.value?.find(content => content.overview) ?? {};
    const {
      header = this.localize('home.welcome.header'),
      copy = this.session.isPublicPortalGuest ? this.localize('home.welcome.publicPortal.copy') : this.localize('home.welcome.copy'),
      imageURL = `../../../assets/img/${defaultImage}`,
    } = overview;

    const overviewTemplate = html`
      <div class="overview-container-wide">
        <div class="d2l-centerer d2l-skeletize">
          <div class="d2l-gutters">
            <div class="overview-container-narrow">
              <span class="overview-content">
                <h1 class="overview-title d2l-heading-1">${header}</h1>
                <p class="overview-text d2l-heading-3">${unsafeHTML(copy)}</p>
                <d2l-button id="mainContent" primary @click=${spaNav(this.navigate, '/activities')}>${this.localize('home.welcome.button')}</d2l-button>
                ${ this._hasBrightspaceInstance ? this._brightspaceNavButton : nothing }
              </span>
              <span class="overview-img" role="presentation" style=${styleMap(this._imageMap(imageURL))}></span>
            </div>
          </div>
        </div>
      </div>
    `;

    const logoImages = localLogos => {
      const logoTemplates = [];
      const isMinLogoCount = (localLogos.length <= 4) && (this._numberOfLogosInCol === 2);
      const logoRowClass = isMinLogoCount ? 'logo-row-min' : 'logo-row';
      const logoContainerClass = isMinLogoCount ? 'logo-container-min' : 'logo-container';
      while (localLogos.length) {
        logoTemplates.push(html`
          <div class=${logoRowClass}>
            ${repeat(localLogos.splice(0, this._numberOfLogosInCol), ({ subjectDetails }) => html`
                <div class=${logoContainerClass}>
                  <img alt=${subjectDetails?.name} class="logo-img" src=${subjectDetails?.imageUrl}>
                </div>
              `)}
          </div>
        `);
      }
      return logoTemplates;
    };

    const carouselPadding = this.session.isPublicPortalGuest ? { 'padding-top' : '0' } : {};
    const academicPartnersTemplate = html`
      <div class="academic-partners-wide">
        <div class="d2l-centerer d2l-skeletize">
          <div class="d2l-gutters">
            <div class="academic-partners-narrow">
              <h2 class="academic-title d2l-heading-2">${this.localize('home.academic.title')}</h2>
              ${this.session.isPublicPortalGuest ? html`<p class="academic-body d2l-body-standard">${this.localize('home.academic.publicPortal.copy')}</p>` : nothing}
              <div class="prev-page button-container">
                ${this.skeleton || (this._providerLogos.length <= this._logoLimit) ? nothing : this._prevButtonTemplate}
              </div>
              <div class="carousel-container" style=${styleMap(carouselPadding)}>
                ${this._providerLogos.length > 0 ? html`
                ${logoImages(this._providerLogos.slice(this._startIndexOfLogos, this._endIndexOfLogos))}
                ` : nothing}
              </div>
              <div
              class="next-page button-container">
                ${(this.skeleton || this._providerLogos.length <= this._logoLimit) ? nothing : this._nextButtonTemplate}
              </div>
            </div>
          </div>
          <div class="academic-partners-login">
            ${this.session.isPublicPortalGuest ? html`<d2l-button id="mainContent" primary @click=${this.toPrograms}>${this.localize('home.welcome.button')}</d2l-button>` : nothing}
          </div>
        </div>
      </div>
    `;

    const helpTemplate = html`
      <div class="help-wide">
        <div class="d2l-centerer">
          <div class="d2l-gutters">
            <div class="help-narrow">
              <img alt=${this.localize('image.alt.helpingHand')}
                class="help-img"
                src="../../../assets/img/helping-hand.png"
                srcset="../../../assets/img/helping-hand.svg">
              <h2 class="help-title d2l-heading-2">${this.localize('home.help.title')}</h2>
              <p class="help-text d2l-body-standard">${this.localize('home.help.text')}</p>
              <d2l-button class="help-button" @click=${spaNav(this.navigate, '/contact-support')}>${this.localize('home.help.button')}</d2l-button>
            </div>
          </div>
        </div>
      </div>
    `;

    const faqTemplate = () => {
      const faqContent = this.faqs?.hasContent ? this.faqs.value : undefined;
      const faqStyle = this.session.isPublicPortalGuest ? { 'margin-bottom': '4rem' } : { 'margin-bottom': 0 };
      return html`
        <div class="faq-wide" style=${styleMap(faqStyle)}>
          <div class="d2l-centerer d2l-skeletize">
            <div class="d2l-gutters">
              <faq-list .faqs=${faqContent}></faq-list>
            </div>
          </div>
        </div>
      `;
    };

    return html`
      ${overviewTemplate}
      ${this._providerLogos?.length > 0 ? academicPartnersTemplate : nothing}
      <home-custom-content ?skeleton=${this.skeleton} .customContent=${this.homePageContent?.value || []}></home-custom-content>
      ${this.session.tenant.learnerTerminology === 'member' ? nothing : faqTemplate()}
      ${this.session.isPublicPortalGuest ? nothing : helpTemplate}
    `;
  }

  _imageMap(image) {
    return {
      backgroundImage: `url('${image}')`,
    };
  }

  /**
   * @description Modern Fisher-Yates shuffle
   * @param {Array} arr
   * @return {Array}
   */
  _shuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const swap = arr[i];
      arr[i] = arr[j];
      arr[j] = swap;
    }
    return arr;
  }

}

window.customElements.define('view-home', ViewHome);
