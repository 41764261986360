import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';

import '../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../../../../shared/components/general/nova-card/nova-card.js';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { convertDateToYMD } from '../../../../../shared/helpers/dateTime.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

const DIALOGS = {
  ENROLLMENT_DATE: 'enrollment-date',
  FAILED: 'failed',
  PASSED: 'passed',
  WITHDRAWN: 'withdrawn',
  CANCELLED: 'cancelled',
  NONE: 'none',
};

class ProviderAdminApplicationForm extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      activity: { type: Object, reflect: false },
      application: { type: Object, reflect: false },
      learnerTerminology: { type: String, reflect: false },
      _enrollDate: { type: String, reflect: false },
      _completedDate: { type: String, reflect: false },
      _selectedCompletionStatus: { type: String, reflect: false },
      _refundAmount: { type: String, reflect: false },
      _cancelReason: { type: String, reflect: false },
      // local enum state property reflecting which confirmation dialog is open
      _dialogOpen: { type: String, reflect: false },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .input-select-wrapper {
          display: inline-block;
          padding: 10px 0;
        }

        d2l-button {
          margin-left: 10px;
        }

        .registration-info {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 0 20px;
          justify-content: flex-start;
        }

        .registration-item {
          padding: 10px 0;
        }

        d2l-input-number {
          max-width: -moz-fit-content;
          max-width: fit-content;
        }
`,
    ];
  }

  constructor() {
    super();
    this._selectedCompletionStatus = 'Pending';
    this.application = new Application();
    this.activity = new Activity();
    this._dialogOpen = DIALOGS.NONE;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    const saveButtonLabel = this.localize('application-call-to-action.template.provider.buttonSave');
    return html`
      <d2l-form id="provider-form">

        <div class="registration-info">
          <!-- enrollment date date picker -->
          ${this._enrollmentDateInputTemplate()}
          <!-- completion status dropdown -->
          ${this._completionStatusInputTemplate()}
          <!-- completion date date picker -->
          ${this._completionDateInputTemplate()}
          <!-- refund amount input -->
          ${this._refundAmountInputTemplate()}
        </div>

        <!-- save button -->
        <d2l-button
          style="margin-left: 0px; margin-top: 26px;"
          aria-label=${saveButtonLabel}
          primary
          @click=${this._handleSaveClick()}
          .disabled=${this._saveIsDisabled()}>
          ${saveButtonLabel}
        </d2l-button>

      </d2l-form>

      <!-- confirmation dialog -->
      ${this.getDialogTemplate()}
    `;
  }

  async updated(_changedProperties) {
    let shouldInitializeProperties = false;
    for (const [propName] of _changedProperties) {
      if ((propName === 'application' && this.application)) {
        shouldInitializeProperties = true;
      }
    }
    if (shouldInitializeProperties) {
      this._initializeProperties();
    }
  }

  _initializeProperties() {
    // initialize local component state
    this._selectedCompletionStatus = this.application.completionStatus || 'Pending';
    // if the app status is pending, pass or fail, then refund percent should always be empty
    // otherwise show the application's stamped refund percent,
    // if this is blank, default to 100%
    this._refundPct = ['Pending', 'Pass', 'Fail'].includes(this._selectedCompletionStatus) ? '' : this.application.refundPct ?? 100;
    this._cancelReason = this.application.cancelReason;
    this._enrollDate = this.application.enrollDate;
    this._completedDate = this.application.completedDate;
  }

  get _shouldConfirmEnrollDate() {
    const enrolledDate = this._enrollDate ? new Date(this._enrollDate).toISOString() : undefined;
    return this._selectedCompletionStatus === 'Pending' &&
    !!enrolledDate && enrolledDate !== this.application.enrollDate &&
    !this.session.settings?.disableEnrollmentDateConfirmation;
  }

  async _openDialogOrSaveApplication() {
    // these "disable" boolean properties all reflect whether
    // the provider admin has previously clicked "don't show me again"
    // in the respective confirmation dialogs during their session
    // meaning clicking "Save" button will update, without opening the dialog to confirm
    const { disableFailedStatusConfirmation,
      disablePassedStatusConfirmation,
      disableWithdrawnStatusConfirmation } = this.session.settings;

    if (this._shouldConfirmEnrollDate) {
      // if a new enrolment date value was just entered and the user has not clicked "don't show me again"
      this._dialogOpen = DIALOGS.ENROLLMENT_DATE;
      return;
    } else if (this._selectedCompletionStatus === 'Cancelled') {
      this._dialogOpen = DIALOGS.CANCELLED;
      return;
    } else if (this._selectedCompletionStatus === 'Fail' && !disableFailedStatusConfirmation) {
      this._dialogOpen = DIALOGS.FAILED;
      return;
    } else if (this._selectedCompletionStatus === 'Pass' && !disablePassedStatusConfirmation) {
      this._dialogOpen = DIALOGS.PASSED;
      return;
    } else if (this._selectedCompletionStatus === 'Withdrawn' && !disableWithdrawnStatusConfirmation) {
      this._dialogOpen = DIALOGS.WITHDRAWN;
      return;
    }

    this._displayNotificationToast();
    await this._updateCompletion();
  }

  _completionStatusChanged(e) {
    this._selectedCompletionStatus = e.target.value;
    // Handle resetting enrollment date when trying to cancel before enrollment
    if (this._selectedCompletionStatus === 'Cancelled' && !this.application.completedDate && !this.application.enrollDate) {
      this._enrollDate = '';
    }
    if (this._selectedCompletionStatus === 'Pending') {
      this._completedDate = '';
    }
    this._refundPct = ['Cancelled', 'Withdrawn'].includes(this._selectedCompletionStatus) ? 100 : '';
  }

  _dialogClose() {
    return async e => {
      this._dialogOpen = DIALOGS.NONE;
      const { action } = e.detail;

      if (action === 'abort') {
        this._cancelReason = this.application.cancelReason;
      }

      if (action === 'done') {
        await this._updateCompletion();
      }
    };
  }

  _dispatchUpdateEvent() {
    // this event bubbles up to the parent to tell it to re-render and pass down a new "application" prop to its children
    this.dispatchEvent(new CustomEvent('update-application', { detail: { application: this.application } }));
  }

  async _updateCompletion() {
    const isoEnrollDate = this._enrollDate ? new Date(this._enrollDate).toISOString() : undefined;
    const isoCompletedDate = this._completedDate ? new Date(this._completedDate).toISOString() : '';
    this.application = await this.client.completeApplication(
      this.application.uuid,
      isoEnrollDate,
      isoCompletedDate,
      this._selectedCompletionStatus,
      this._cancelReason,
      this._refundPct);
    this._dispatchUpdateEvent();
  }

  _displayNotificationToast() {
    const message = this.localize('application-call-to-action.discountCode.save.success');
    const type = 'default';
    this.session.toast({ type, message, noAutoClose: false });
  }

  get _formComponentIds() {
    return {
      completionDate: 'provider-form-completion-date',
      discountCode: 'provider-form-discount-code',
      enrollmentDate: 'provider-form-enrollment-date',
      refund: 'provider-form-refund',
    };
  }

  _createErrorMsg(invalidComponents) {
    if (invalidComponents.length === 1) {
      return this.localize(`application-call-to-action.providerForm.error.empty.${invalidComponents[0].label}`);
    }
    else if (invalidComponents.length > 1) {
      const localizedLabels = invalidComponents.map(component => component.localized);
      const last = localizedLabels.pop();
      const invalidComponentsNames = `${localizedLabels.join(', ')} ${this.localize('application-call-to-action.providerForm.error.template.and')} ${last}`;
      return `${this.localize('application-call-to-action.providerForm.error.template')} ${invalidComponentsNames}`;
    }
  }

  _handleSaveClick() {
    return () => {
      const providerForm = this.shadowRoot.querySelector('[id="provider-form"]');
      const invalidComponents = [];
      Object.keys(this._formComponentIds).forEach(label => {
        const id = this._formComponentIds[label];
        const formElement = providerForm.querySelector(`[id=${id}]`);
        // validation of disabled element not needed
        if (formElement && formElement.disabled) {
          return;
        }
        // get all invalid elements
        if ((id !== 'provider-form-refund' && formElement && !formElement.value) || (id === 'provider-form-refund' && formElement && isNaN(formElement.value))) {
          invalidComponents.push({ label, localized: this.localize(`application-call-to-action.input.${label}.label`) });
        }

        if (id === 'provider-form-refund' && formElement && formElement?.value > 100 || formElement?.value < 0) {
          invalidComponents.push({ label: `invalid_${label}`, localized: this.localize(`application-call-to-action.input.${label}.validLabel`) });
        }
      });

      if (new Date(this._enrollDate) > new Date(this._completedDate)) {
        this.session.toast({
          type: 'critical',
          message: this.localize('application-call-to-action.providerForm.error.completionBeforeEnrollment'),
          noAutoClose: false,
        });
        return;
      }
      if (invalidComponents.length === 0) {
        return this._openDialogOrSaveApplication();
      } else {
        this.session.toast({
          type: 'critical',
          message: this._createErrorMsg(invalidComponents),
          noAutoClose: false,
        });
      }
    };
  }

  get _completionStatuses() {
    // a map for each possible completion status option in the dropdown input
    // `alwaysEnabled`: whether the dropdown option is always enabled, or sometimes greyed out/disabled
    // `completionDateEnabled`: whether the completion date input is enabled/required when this status is selected
    // `refundEnabled`: whether the refund percent input is enabled/required when this status is selected
    // `localeKey`: the lang term for the dropdown option value
    return {
      'Pending': { alwaysEnabled: true, completionDateEnabled: false, refundEnabled: false, localeKey: 'application-call-to-action.status.pending' },
      'Pass': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: false, localeKey: 'application-call-to-action.status.pass' },
      'Fail': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: false, localeKey: 'application-call-to-action.status.fail' },
      'Withdrawn': { alwaysEnabled: false, completionDateEnabled: true, refundEnabled: true, localeKey: 'application-call-to-action.status.withdrawn' },
      'Cancelled': { alwaysEnabled: true, completionDateEnabled: true, refundEnabled: true, localeKey: 'application-call-to-action.status.cancelled' },
    };
  }

  _saveTargetValue(propertyName) {
    return e => {
      this[propertyName] = e.target.value;
    };
  }

  _saveIsDisabled() {
    // if the application is "complete" then you can't update/save anymore
    return ['Pass', 'Fail', 'Cancelled', 'Withdrawn'].includes(this.application?.completionStatus);
  }

  _completionDateInputTemplate() {
    const completionDateInputLabel = this.localize('application-call-to-action.input.completionDate.label');
    return html`
        <d2l-input-date
          id="provider-form-completion-date"
          class="registration-item"
          label=${completionDateInputLabel}
          title=""
          .minValue=${this._enrollDate || convertDateToYMD(new Date())}
          .disabled=${!this._completionStatuses[this._selectedCompletionStatus].completionDateEnabled || this._saveIsDisabled()}
          .required=${this._completionStatuses[this._selectedCompletionStatus].completionDateEnabled}
          .value=${this._completedDate}
          @change=${this._saveTargetValue('_completedDate')}></d2l-input-date>
      `;
  }

  _completionStatusInputTemplate() {
    const completionStatusInputLabel = this.localize('application-call-to-action.input.completionStatus.label');
    return html`
        <div class="input-select-wrapper">
          <label for="completion-status" class="d2l-input-label">${completionStatusInputLabel}</label>
          <select id="completion-status"
            class="d2l-input-select"
            @change=${this._completionStatusChanged}
            .value=${this._selectedCompletionStatus}
            .disabled=${this._saveIsDisabled()}
          >
          ${Object.keys(this._completionStatuses).map(key => html`
            <option
              .value=${key}
              .selected="${key === this.application.completionStatus}"
              .disabled=${!this._completionStatuses[key].alwaysEnabled && !this.application.enrollDate}
            >
              ${this.localize(this._completionStatuses[key].localeKey)}
            </option>
          `)}
          </select>
        </div>
      `;
  }

  _enrollmentDateInputTemplate() {
    const enrollmentDateInputLabel = this.localize('application-call-to-action.input.enrollmentDate.label');

    // enroll date input is disabled if an enroll date already exists,
    // if application is Pass/Fail/Withdrawn/Cancelled (or is cancelled and doesn't have a completion date)
    // or if condition based on provider payment method is fulfilled
    const isDisabled = this.application?.enrollDate ||
      this._saveIsDisabled() ||
      (this._selectedCompletionStatus === 'Cancelled' && !this.application?.completedDate);
    return html`
        <d2l-input-date
          id="provider-form-enrollment-date"
          class="registration-item"
          label=${enrollmentDateInputLabel}
          .value=${this._enrollDate}
          .disabled=${isDisabled}
          style="padding-left: 0px;"
          @change=${this._saveTargetValue('_enrollDate')}></d2l-input-date>
      `;
  }

  _refundAmountInputTemplate() {
    const refundInputLabel = this.localize('application-call-to-action.input.refund.label');
    return html`
        <d2l-input-number
          id="provider-form-refund"
          class="registration-item"
          label=${refundInputLabel}
          unit="%"
          min="0"
          max="100"
          input-width="6rem"
          .disabled=${!this._completionStatuses[this._selectedCompletionStatus].refundEnabled || this._saveIsDisabled()}
          .required=${this._completionStatuses[this._selectedCompletionStatus].refundEnabled}
          @change=${this._saveTargetValue('_refundPct')}
          .value=${this._refundPct}></d2l-input-number>
      `;
  }

  get _commonDialogData() {
    return {
      activityTitle: this.activity?.title,
      activityType: this.activity.getTranslatedValue('type'),
      username: this.application?.user.getDisplayName?.(),
      completionStatus: this._selectedCompletionStatus,
      learnerTerminology: this.learnerTerminology,
    };
  }

  cancelledDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
      refundPct: this._refundPct,
    };
    const belowContentTitle1 = this.localize('application-call-to-action.dialog.cancelled.reasonInfo.1');
    const belowContentTitle2 = this.localize('application-call-to-action.dialog.cancelled.reasonInfo.2');
    const reasonInputLabel = this.localize('application-call-to-action.dialog.cancelled.reason.label');
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.CANCELLED}
        ?disableConfirm=${!this._cancelReason}
        type="cancelledStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
        <div slot="below-content">
          <b>${belowContentTitle1}</b> ${belowContentTitle2}
          <d2l-input-textarea
            label=${reasonInputLabel}
            maxlength=300
            label-hidden
            required
            title=""
            @input=${this._saveTargetValue('_cancelReason')}></d2l-input-textarea>
        </div>
      </confirmation-dialog>
    `;
  }

  enrollDateDialog() {
    const dialogData = {
      ...this._commonDialogData,
      enrollDate: this._enrollDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.ENROLLMENT_DATE}
        type="enrollmentDateConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  failedDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.FAILED}
        type="failedStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  passedDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.PASSED}
        type="passedStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  withdrawnDialog() {
    const dialogData = {
      ...this._commonDialogData,
      completionDate: this._completedDate,
      refundPct: this._refundPct,
    };
    return html`
      <confirmation-dialog
        ?opened=${this._dialogOpen === DIALOGS.WITHDRAWN}
        type="withdrawnStatusConfirmation"
        .data=${dialogData}
        .displayNotificationToast=${this._displayNotificationToast}
        @d2l-dialog-close=${this._dialogClose()}>
      </confirmation-dialog>
    `;
  }

  getDialogTemplate() {
    switch (this._dialogOpen) {
      case DIALOGS.CANCELLED:
        return this.cancelledDialog();
      case DIALOGS.ENROLLMENT_DATE:
        return this.enrollDateDialog();
      case DIALOGS.FAILED:
        return this.failedDialog();
      case DIALOGS.PASSED:
        return this.passedDialog();
      case DIALOGS.WITHDRAWN:
        return this.withdrawnDialog();
      case DIALOGS.NONE:
        return nothing;
      default:
        return nothing;
    }
  }

}

window.customElements.define('provider-admin-application-form', ProviderAdminApplicationForm);
