import { bodyStandardStyles, heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class HomeCustomContent extends SkeletonMixin(LocalizeNova(LitElement)) {

  static get properties() {
    return {
      customContent: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      bodyStandardStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        :host {
          display: block;
        }

        .d2l-centerer {
          margin: 0 auto;
          max-width: 1230px;
        }

        .d2l-gutters {
          padding-left: 3.439%;
          padding-right: 3.439%;
          position: relative;
        }

        .custom-wide {
          margin: 2rem 0;
        }

        .custom-narrow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .custom-content {
          flex: 0 0 50%;
          padding: 4rem 0;
        }

        .custom-title {
          font-weight: 900;
        }

        .custom-img {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          flex: 0 2 40%;
        }

        .custom-content.left,
        .custom-img.left {
          order: 1;
        }

        .custom-content.right,
        .custom-img.right {
          order: 2;
        }

        @media (max-width: 615px) {
          .d2l-gutters {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        @media (max-width: 767px) {
          .custom-wide {
            margin: 0;
          }

          .custom-content {
            padding: 2rem 0;
          }
        }

        @media (min-width: 1230px) {
          .d2l-gutters {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.customContent = [];
  }

  render() {
    return this.customContent.length === 0
      ? nothing
      : repeat(this.customContent.filter(c => !c.overview),
        content => content,
        (content, idx) => html`${this._templateBuilder(content, idx % 2 !== 0)}`);
  }

  _imageStyle(image) {
    return {
      backgroundImage: `url('${image}')`,
    };
  }

  _templateBuilder(content, isOdd) {
    const contentClasses = { 'custom-content' : true, 'left' : !isOdd, 'right' : isOdd };
    const titleClasses = { 'd2l-heading-1' : true, 'custom-title' : true, 'left' : !isOdd, 'right' : isOdd };
    const copyClasses = { 'd2l-body-standard' : true, 'custom-copy' : true, 'left' : !isOdd, 'right' : isOdd };
    const imgClasses = { 'custom-img' : true, 'left' : isOdd, 'right' : !isOdd };
    return html`
      <div class="custom-wide">
        <div class="d2l-centerer">
          <div class="d2l-gutters">
            <div class="custom-narrow">
              <span class=${classMap(contentClasses)}>
                <h1 class=${classMap(titleClasses)}>${content.header}</h1>
                <div class=${classMap(copyClasses)}>${unsafeHTML(content.copy)}</div>
              </span>
              <span class=${classMap(imgClasses)} role="presentation" style=${styleMap(this._imageStyle(content.imageURL))}></span>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define('home-custom-content', HomeCustomContent);
