import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import { ReportWidget } from '../../models/report-widget/report-widget.js';

const DEFAULT_OTHER_OPTIONS = {
  resultsPerPage: 10,
  page: 0,
};

export const ReportMixin = dedupeMixin(superclass => class extends RequesterMixin(superclass) {

  static get properties() {
    return {
      type: { type: String, reflect: true },
      from: { type: String, reflect: true },
      to: { type: String, reflect: true },
      timeframe: { type: String, reflect: true },
      employerIds: { type: Array, reflect: false },
      providerIds: { type: Array, reflect: false },
      otherOptions: { type: Object, reflect: true },
      _data: { type: Object, attribute: false },
      _title: { type: String, attribute: false },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  async updated(_changedProperties) {
    super.update(_changedProperties);
    let executeUpdate = false;
    _changedProperties.forEach((oldValue, propName) => {
      if (['from', 'to', 'timeframe', 'employerIds', 'providerIds'].some(e => e === propName) && oldValue !== this[propName]) {
        executeUpdate = !!(this.employerIds && this.providerIds && ((this.from && this.to) || this.timeframe));
      }
      if (propName === 'type') {
        this._title = `report.${this.type}.title`;
      }
    });

    if (executeUpdate) {
      this.skeleton = true;
      this.otherOptions = {
        ...DEFAULT_OTHER_OPTIONS,
        ...this.otherOptions,
      };
      const options = { employerIds: this.employerIds, providerIds: this.providerIds, from: this.from, to: this.to, timeframe: this.timeframe, ...this.otherOptions };

      const reportData = await this.client.executeReportWidget(this.type, options);

      this._data = this.employerIds?.length ? new ReportWidget(reportData) : new ReportWidget();
      this.skeleton = false;
      await this._dispatchChangeLoadingStatus(this.type, true);
    }
  }

  async _dispatchChangeLoadingStatus(type, loadingStatus) {
    const dispatchChangeLoadingStatus = new CustomEvent('loading-status-event', {
      detail: {
        type,
        loadingStatus,
      },
      bubbles: true,
      composed: true });

    await this.dispatchEvent(dispatchChangeLoadingStatus);
  }

});
