import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/button/floating-buttons.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/link/link.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { CustomAttribute, CustomAttributeModelSchema } from '../../../../../../shared/models/custom-attribute.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../../shared/components/general/nova-async-button/nova-async-button.js';
import '../../../../../shared/components/inputs/nova-json-input/nova-json-input-array/nova-json-input-array.js';
import '../../../../../shared/components/general/nova-tooltip/nova-tooltip.js';

export default class ManageCustomAttributes extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      tenantId: { type: String },
      userCustomAttributes: { type: Object },
      applicationCustomAttributes: { type: Object },
    };
  }

  static get styles() {
    return [
      css`
        .app-heading {
          margin-top: 3rem;
        }

        .help-container {
          background-color: var(--d2l-color-regolith);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
          padding: 1rem;
        }

        .save-button {
          margin-top: 2rem;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  get _userCustomAttributesElement() {
    return this.shadowRoot.getElementById('user-custom-attributes');
  }

  get _applicationCustomAttributesElement() {
    return this.shadowRoot.getElementById('application-custom-attributes');
  }

  _validateJson() {
    const userAttributesValid = this._userCustomAttributesElement.allInputsHaveValidJson;
    const applicationAttributesValid = this._applicationCustomAttributesElement.allInputsHaveValidJson;
    if (!userAttributesValid && !applicationAttributesValid) {
      throw new Error(this.localize('manage-custom-attributes.save.bothAttributesInvalid.json'));
    } else if (!userAttributesValid) {
      throw new Error(this.localize('manage-custom-attributes.save.userAttributesInvalid.json'));
    } else if (!applicationAttributesValid) {
      throw new Error(this.localize('manage-custom-attributes.save.applicationAttributesInvalid.json'));
    }
  }

  _validatePerNovaModelSchema() {
    const userAttributesValidPerSchema = this._userCustomAttributesElement.allInputsValidPerNovaSchema;
    const applicationAttributesValidPerSchema = this._applicationCustomAttributesElement.allInputsValidPerNovaSchema;
    if (!userAttributesValidPerSchema && !applicationAttributesValidPerSchema) {
      throw new Error(this.localize('manage-custom-attributes.save.bothAttributesInvalid.schema'));
    } else if (!userAttributesValidPerSchema) {
      throw new Error(this.localize('manage-custom-attributes.save.userAttributesInvalid.schema'));
    } else if (!applicationAttributesValidPerSchema) {
      throw new Error(this.localize('manage-custom-attributes.save.applicationAttributesInvalid.schema'));
    }
  }

  _saveCustomAttributes() {
    return async() => {
      const toast = { message: this.localize('manage-custom-attributes.save.success'), type: 'default' };
      try {
        this._validateJson();
        this._validatePerNovaModelSchema();

        const savePromises = [
          this.client.updateCustomAttributes(this.tenantId, this._userCustomAttributesElement.value),
          this.client.updateApplicationCustomAttributes(this.tenantId, this._applicationCustomAttributesElement.value),
        ];
        await Promise.all(savePromises);
      } catch (e) {
        toast.message = e.message || await e.text();
        toast.type = 'critical';
      }
      this.session.toast(toast);
    };
  }

  get _defaultNewObject() {
    const baseObject = (new CustomAttribute()).toJSON();
    return {
      name: this.localize('manage-custom-attributes.defaultNameValue'),
      ...baseObject,
    };
  }

  get _hiddenKeys() {
    return ['_modelType'];
  }

  getCustomAttributeObjectArray(attributes) {
    return attributes?.map(attr => { return { ...attr }; }) || [];
  }

  get _saveButton() {
    return html`
      <nova-async-button
        class="save-button"
        primary
        .action=${this._saveCustomAttributes()}>
        ${this.localize('manage-general.buttonSave')}
      </nova-async-button>
    `;
  }

  get _customAttributesHtml() {
    return html`
      <h2>${this.localize('manage-custom-attributes.user.heading')}</h2>
      <p>${this.localize('manage-custom-attributes.user.description')}</p>
      <nova-json-input-array
        id="user-custom-attributes"
        label=${this.localize('manage-custom-attributes.user.label')}
        title-key="name"
        .hiddenKeys=${this._hiddenKeys}
        .defaultNewObject=${this._defaultNewObject}
        .novaModelSchema=${new CustomAttributeModelSchema()}
        .objectArray=${this.getCustomAttributeObjectArray(this.userCustomAttributes)}>
      </nova-json-input-array>

      <h2 class="app-heading">${this.localize('manage-custom-attributes.application.heading')}</h2>
      <p>${this.localize('manage-custom-attributes.application.description')}</p>
      <nova-json-input-array
        id="application-custom-attributes"
        label=${this.localize('manage-custom-attributes.application.label')}
        title-key="name"
        .hiddenKeys=${this._hiddenKeys}
        .defaultNewObject=${this._defaultNewObject}
        .novaModelSchema=${new CustomAttributeModelSchema()}
        .objectArray=${this.getCustomAttributeObjectArray(this.applicationCustomAttributes)}>
      </nova-json-input-array>
    `;
  }

  get _helpContainerHtml() {
    const documentationUrl = 'https://desire2learn.atlassian.net/wiki/spaces/NVA/pages/3790700547/Custom+Attributes';
    return html`
      <div class="help-container">
        <d2l-link target="_blank" href=${documentationUrl}>${this.localize('manage-custom-attributes.helpText')}</d2l-link>
      </div>
    `;
  }

  render() {
    if (!this.tenantId) return nothing;
    return html`
      ${this._helpContainerHtml}
      ${this._customAttributesHtml}
      ${this._saveButton}
    `;
  }
}

window.customElements.define('manage-custom-attributes', ManageCustomAttributes);
