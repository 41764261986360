import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import '@brightspace-ui/core/components/object-property-list/object-property-list.js';
import '@brightspace-ui/core/components/object-property-list/object-property-list-item.js';
import '@brightspace-ui/core/components/meter/meter-linear.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/card/card-content-meta.js';
import '@brightspace-ui/core/components/card/card.js';
import { bodySmallStyles, bodyStandardStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../activity-card-wide/activity-card-wide.js';
import '../../general/nova-link-card/nova-link-card.js';
import '../../skills/skill-chip-list/skill-chip/skill-chip.js';
import '../../skills/skill-chip-list/skill-chip-list.js';
import '../../general/nova-status-indicator/nova-status-indicator.js';
import ActivitiesHelper from '../../../../../shared/helpers/activities.js';
import Activity from '../../../../../shared/models/activity/activity.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

function isMobile() {
  return window.matchMedia('(max-width: 767px)').matches;
}

class ActivityHeroCard extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      activity: { type: Object },

      _isMobile: { type: Boolean, state: true },
      _activityStatus: { type: Object },
      _skillProfile: { type: Object },
      _providerTenant: { type: Object },
      _savedToMyList: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      bodyStandardStyles,
      css`
        nova-link-card {
          width: 100%;
        }
        d2l-object-property-list {
          color: black;
        }
        :host([skeleton]) .activity-hero-card-image {
          visibility: hidden;
        }
        :host(:hover) .activity-title {
          color: var(--d2l-color-celestine);
          text-decoration: underline;
        }
        .nova-link-card-content {
          display: flex;
          flex-direction: row;
          width: 100%;
        }
        .activity-hero-card-image-wrapper {
          border-radius: 6px 0 0 6px;
          height: 100%;
          max-width: 350px;
          min-width: 350px;
          transition: width 0.5s;
          transition: min-width 0.5s;
          width: 350px;
        }
        .activity-hero-card-content {
          display: flex;
          flex-direction: column;
          gap: 24px;
          padding: 1.2rem 0.8rem 1.2rem 0.8rem;
          width: 100%;
        }
        .activity-content-header {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap-reverse;
          gap: 12px;
          justify-content: space-between;
        }
        .activity-provider-logo-wrapper {
          display: flex;
          flex: 0 0 auto;
        }
        .add-to-my-list-container {
          display: flex;
          flex: 1 0 auto;
          justify-content: end;
        }
        .activity-provider-logo {
          flex: 0 0 auto;
          height: 50px;
          max-width: 100%;
          width: auto;
        }
        .activity-details {
          border-bottom: 2px solid var(--d2l-color-gypsum);
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .activity-details > * {
          margin-bottom: 24px;
        }
        .activity-skills-list-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
        }
        .activity-status-indicator {
          left: 22px;
          position: absolute;
          top: 26px;
          z-index: 5;
        }
        .activity-title {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 1.4rem;        /* fallback */
          margin: 0;
          max-height: calc(1.4rem * 2);       /* fallback */
          overflow: hidden;
          padding-bottom: 0.05rem; /* fix for typography descenders getting cutoff by overflow: hidden */
          text-overflow: ellipsis;
        }
        .text-black {
          color: #000000 !important;
        }
        .activity-program-summary-container {
          max-width: 444px;
        }
        @media (max-width: 768px) {
          :host {
            --skill-chip-font-size: 14px;
          }
          .activity-hero-card-image-wrapper {
            display: none;
          }
        }
        @media (max-width: 1069px) {
          .activity-hero-card-image-wrapper {
            height: 100%;
            max-width: 250px;
            min-width: 250px;
            transition: width 0.5s;
            transition: min-width 0.5s;
            width: 250px;
          }
          .activity-program-summary-container {
            max-width: 100%;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = null;

    this._isMobile = isMobile();
    this._providerTenant = null;
    this._savedToMyList = false;
    this._activityChildrenCompleted = 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.activity = this.activity ? new Activity(this.activity) : this.activity;

    this._savedToMyList = this.session.user.getSetting('myList')?.includes(this._activity?.id);
    window.addEventListener('resize', this._handleWindowResize.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('resize', this._handleWindowResize);
  }

  async firstUpdated() {
    super.firstUpdated();
    if (!this.skeleton) {
      this.skeleton = true;
      await this._hydrateProfile();
      this.skeleton = false;
    }
  }

  async updated(_changedProperties) {
    let flagTenantChange = false;
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'activity' && this.activity?.id && oldValue?.provider !== this.activity?.provider) {
        flagTenantChange = true;
      }
    }
    if (flagTenantChange) {
      await this._getProviderTenant();
    }
  }

  get activityHeroCardAriaLabel() {
    return [
      this.activity?.title,
      this._providerTenant?.name,
      this.activity?.getTranslatedValue('type'),
      this.activity?.getTranslatedValue('delivery'),
      this.activity?.getTranslatedValue('duration'),
    ].filter(content => content).join(', ');
  }

  get href() {
    if (!this.skeleton && this.activity?.id) return `/activities/${this.activity.id}`;
    return undefined;
  }

  get isProgramWithChildren() {
    return this.activity?.type === 'program' && this.activity.children && this.activity.children.length > 0;
  }

  get skillsListTemplate() {
    const skills = this.activity?.visibleSkills || [];
    if (skills.length === 0) return nothing;

    let modifiedSkills = [];
    if (this._skillProfile) {
      const { roleSkills, setSkills } = this._skillProfile;

      modifiedSkills = skills.map(skill => {
        const newSkill = { ...skill };
        if (setSkills.some(setSkill => setSkill.skillId === skill.id)) {
          newSkill.isInSkillProfile = true;
        }
        if (roleSkills.some(roleSkill => roleSkill.id === skill.id)) {
          newSkill.isRelatedToRole = true;
        }
        return newSkill;
      });
    }

    return html`
      <div class="d2l-skeletize">
        <skill-chip-list
          max-skills=${this._isMobile ? 1 : 5}
          .skills=${modifiedSkills.length > 0 ? modifiedSkills : skills}
          truncate-text
        ></skill-chip-list>
      </div>
    `;
  }

  get programSummaryTemplate() {
    if (!this.isProgramWithChildren) {
      return nothing;
    }

    return html`
      <div class="activity-details-summary">
        <div class="activity-program-summary-container d2l-skeletize">
          <d2l-meter-linear value="${this._activityChildrenCompleted}" max="${this.activity.children.length}" text=${this.localize('activity.type.program.completionCount')}></d2l-meter-linear>
        </div>
      </div>
    `;
  }

  get courseSummaryTemplate() {
    if (this.activity?.type !== 'course') {
      return nothing;
    }

    return html`
      <div class="activity-details-summary">
        <div class="activity-course-summary-container d2l-skeletize">
          <d2l-icon class="start-date-icon" icon="tier1:calendar"></d2l-icon>
          <span class="d2l-body-small start-date-text text-black">${this.localize('view-activity.activityMetadata.footer.nextSession')} ${this.activity.formattedStartDate}</span>
        </div>
      </div>
    `;
  }

  get myListIcon() {
    return this._savedToMyList ? 'tier2:subscribe-filled' : 'tier2:subscribe-hollow';
  }

  get myListText() {
    return this._savedToMyList ? 'view-activity.removeFromMyList' : 'view-activity.addToMyList';
  }

  get _indicatorTemplate() {
    const localizationKey = this.activity?.getActivityStatusLocalizationKey(this.session?.tenantId);

    if (!localizationKey) {
      return nothing;
    }

    return html`
      <nova-status-indicator text=${this.localize(localizationKey)}></nova-status-indicator>
    `;
  }

  render() {
    if (this._isMobile) {
      return html`
        <activity-card-wide .activity=${this.activity} ?skeleton=${this.skeleton} fill></activity-card-wide>
      `;
    }

    return html`
      <nova-link-card
        title=${ifDefined(this.activity?.title)}
        href=${ifDefined(this.href)}
        text=${ifDefined(this.activity?.title)}
        aria-label=${ifDefined(this.ariaLabel)}
      >
        <div class="nova-link-card-content">
          <div class="activity-hero-card-image-wrapper d2l-skeletize" style="background-image: url(${ifDefined(this.activity?.cardImageUrl && !this.skeleton ? this.activity.cardImageUrl : undefined)}); background-size: cover; background-repeat: no-repeat; background-position: left;">
            <div class="activity-status-indicator">
              ${this._indicatorTemplate}
            </div>
          </div>
          <div class="activity-hero-card-content">
            <div class="activity-content-header">
              <div class="activity-provider-logo-wrapper d2l-skeletize">
                <img class="activity-provider-logo" src=${ifDefined(this._providerTenant?.imageUrl)} alt=${ifDefined(this.activity?.title)} />
              </div>
              <div class="add-to-my-list-container d2l-skeletize">
                <d2l-button-subtle
                  @click=${this._updateMyList}
                  @keydown=${this._handleButtonKeyDown}
                  icon=${this.myListIcon}
                  text=${this.localize(this.myListText)}>
                </d2l-button-subtle>
              </div>
            </div>
            <div class="activity-details">
              <div class="activity-details-title d2l-skeletize">
                <h4 class="activity-title d2l-body-standard">${ifDefined(this.activity?.title)}</h4>
              </div>
              <div class="activity-details-properties">
                <d2l-object-property-list class="text-black">
                  <d2l-object-property-list-item class="text-black" text=${ifDefined(this.activity?.getTranslatedValue('type'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
                  <d2l-object-property-list-item class="text-black" text=${ifDefined(this.activity?.getTranslatedValue('duration'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
                  <d2l-object-property-list-item class="text-black" text=${ifDefined(this.activity?.getTranslatedValue('delivery'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
                </d2l-object-property-list>
              </div>
              ${this.programSummaryTemplate}
              ${this.courseSummaryTemplate}
            </div>
            <div class="activity-skills">
              ${this.skillsListTemplate}
            </div>
          </div>
        </div>
      </nova-link-card>
    `;
  }

  async _getProviderTenant() {
    this._providerTenant = await this.client.fetchTenant(this.activity.provider);
  }

  async _handleButtonKeyDown(e) {
    e.stopPropagation();
    if (e.key === 'Enter' || e.key === ' ') {
      await this._updateMyList(e);
    }
  }

  async _hydrateProfile() {
    if (this.session.user.tenantId && this.session.user.guid) {
      try {
        const { skills: setSkills } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);
        const skillSubcategoriesForUserRole = await this.client.getSkillSubcategoriesForTitle(this.session?.user.getSetting('selectedTitleId'), this.session.user.tenantId);
        const roleSkills = skillSubcategoriesForUserRole.flatMap(skillSubcategory => skillSubcategory.skills);
        this._skillProfile = { setSkills, roleSkills };
        this._savedToMyList = await this.session?.user?.getSetting('myList')?.includes(this.activity.id);
      } catch (e) {
        console.error('Error fetching skill profile: ', e);
        return null;
      }
    }

    await this._fetchActivityCompletion();
  }

  async _fetchActivityCompletion() {
    if (this.isProgramWithChildren) {
      try {
        const applications = await this.client.getActivityCompletion(this.activity.id);
        const completionSummary = ActivitiesHelper.calculateCompletion(this.activity, applications);
        this._activityChildrenCompleted = completionSummary.summary.complete;
      } catch (e) {
        console.error('Error fetching activity completion: ', e);
        return Promise.resolve(null);
      }
    } else {
      return Promise.resolve(null);
    }
  }

  async _updateMyList(e) {
    e.stopPropagation();

    try {
      this._savedToMyList = !this._savedToMyList;
      await this.client.toggleMyListActivity(this.activity.id);
    } catch (error) {
      if (error?.status !== 405) {
        console.error(error);
        this.session.toast({ type: 'critical', message: this.localize('view-activity.myListError') });
      }
      return;
    }
    const message = this._savedToMyList ? this.localize('view-activity.toast.added') : this.localize('view-activity.toast.removed');
    this.session.toast({ type: 'default', message, noAutoClose: false });
  }

  _handleWindowResize() {
    this._isMobile = isMobile();
  }
}

window.customElements.define('activity-hero-card', ActivityHeroCard);
