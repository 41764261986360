import { html, nothing } from 'lit';

import '@brightspace-ui/core/components/colors/colors.js';
import './activity-financial-details-coverage/activity-financial-details-coverage.js';
import './activity-financial-details-line-item/activity-financial-details-line-item.js';
import '../../../../admin/components/in-place-dropdown/in-place-dropdown.js';

import { DEFAULT_CURRENCY } from '../../../../../shared/constants.js';
import { makeCurrencyAccessible } from '../../../../shared/helpers/generic-templates/generic-templates.js';
import { novaLocalize } from '../../../../../shared/l10n/localize.js';

// Skeletons
export const skeletonContent = () => {
  return html`
    <div slot="primary" class="skeleton-content-container">
      <financial-details-line-item skeleton
        amount='$0000 CAD'
        description='skeleton placeholder text'>
      </financial-details-line-item>
      <financial-details-line-item
        skeleton
        amount='$000 CAD'
        description='placeholder text'>
      </financial-details-line-item>
      <financial-details-line-item skeleton
        amount='$0000 - $0000 CAD'
        description='skeleton phrase(s)'>
      </financial-details-line-item>
    </div>`;
};

const skeletonFooterContent = () => {
  return html`
    <p class="d2l-skeletize-paragraph-3 skeleton-footer-content"> --- </p>
  `;
};

export const activityPriceLineItem = ({
  activity,
  editable,
  formattedActivityCost,
  isActivityPopulated,
  saveCostHandler,
  skeletonState,
}) => {

  if (isActivityPopulated && (!activity.hasTag('allowRequest'))) return nothing;

  const getRequestType = activity.hasTag('submitMyOwn') ? novaLocalize('activity-fees.submitOwnCost') :
    novaLocalize('activity-fees.activityCost', { type: activity.getTranslatedValue('type') });
  const description = isActivityPopulated ? getRequestType : novaLocalize('activity-fees.programOrCourseCost');

  const amountTemplate = isActivityPopulated
    ? html`
      <in-place-dropdown id="cost-dropdown" .editable=${editable} @nova-in-place-save=${saveCostHandler}>
        <div slot="edit-form">
          <d2l-input-number
            required
            value="${activity.tempCost.inDollars()}"
            id="cost"
            max-fraction-digits="2"
            min="0"
            input-width="100px"
            label=${novaLocalize('activity-fees.overallCost')}></d2l-input-number>
        </div>
        <span slot="content" aria-hidden="true">${formattedActivityCost}</span>
      </in-place-dropdown>`
    : html`---`;

  const offscreenTemplate = isActivityPopulated
    ? html`<span class="d2l-offscreen">${makeCurrencyAccessible(formattedActivityCost)}</span>`
    : nothing;

  // Stop-gap solve, if skeleton loading, leverage <line-item>'s skeleton with some placeholder info
  return skeletonState.content
    ? html`<financial-details-line-item
        class="line-item activity-price"
        skeleton
        .amount=${'-----'}
        .description=${novaLocalize('activity-fees.programOrCourseCost')}>
      </financial-details-line-item>`
    : html`
      <div class="line-item activity-price d2l-body-compact">
        <span class="description">${description}</span>
        ${offscreenTemplate}
        <span class="amount">${amountTemplate}</span>
      </div>
    `;
};

export const childActivitiesLineItem = (
  childActivityCosts,
  childCourses) => {
  if (!childCourses || Object.values(childActivityCosts).every(v => !v)) return nothing;

  const amount = childActivityCosts.max !== childActivityCosts.min
    ? `${childActivityCosts.min} - ${childActivityCosts.max}`
    : `${childActivityCosts.min}`;

  const isCourseRequestable = childCourses.map(course => {
    return course?.tags?.tagValues.includes('allowRequest');
  }).every(hasAllowRequest => hasAllowRequest === true);
  if (!isCourseRequestable) return nothing;

  const description = novaLocalize('activity-fees.coursePrices');

  return html`
    <financial-details-line-item class="line-item child-activity-prices"
      .amount=${amount}
      .description=${description}>
    </financial-details-line-item>
  `;
};

// Secondary slot line items
export const companyLineItem = ({
  formattedCompanyCost,
  formattedCompanyCostInProviderCurrency,
  isActivityPopulated,
  isBold = false,
  isTooltipVisible = false,
  providerOperatingCurrency,
  useCoverageComponent = false,
  tenant }) => {
  let amount = '---';

  if (isActivityPopulated) {
    amount = formattedCompanyCostInProviderCurrency || '---';
  }

  if (tenant.learnerTerminology !== 'employee') {
    return nothing;
  }

  let description = null;
  let coverageComponent = html`<financial-details-coverage slot="description" class="d2l-skeletize" .learnerTerminology="${tenant.learnerTerminology}"></financial-details-coverage>`;
  if (!useCoverageComponent) {
    description = novaLocalize('general.costTo.companyName', { companyName: tenant.name });
    coverageComponent = nothing;
  }

  const providerCurrency = providerOperatingCurrency || DEFAULT_CURRENCY;
  const tooltipObj = {
    exists: isTooltipVisible,
    mainText: formattedCompanyCostInProviderCurrency,
    tooltipText: html`
        <b>${novaLocalize('activity-fees.coverage.tooltip.target', { coverage: formattedCompanyCost })}</b>
        <br>
        ${novaLocalize(`activity-fees.coverage.tooltip.description.${tenant.learnerTerminology}`, { providerCurrency })}
    `,
  };

  return html`
    <financial-details-line-item
      class="line-item company-amount"
      ?align-baseline=${useCoverageComponent}
      ?bold=${isBold}
      .amount=${amount}
      .tooltip=${tooltipObj}
      .description=${description}>
      ${coverageComponent}
    </financial-details-line-item>
  `;
};

export const employeeLineItem = ({
  formattedEmployeeCost,
  isActivityPopulated,
  isBold = false,
  learnerTerminology }) => {
  const amount = isActivityPopulated ? formattedEmployeeCost : '---';
  const description = novaLocalize(`activity-fees.${learnerTerminology}.cost`);

  return html`
    <financial-details-line-item
      class="employee-amount"
      ?bold=${isBold}
      .amount=${amount}
      .description=${description}>
    </financial-details-line-item>
  `;
};

export const paidLineItem = ({
  formattedPaidAmount,
  isActivityPopulated }) => {
  const amount = isActivityPopulated ? formattedPaidAmount : '---';
  const description = novaLocalize('application.status.paid');

  return html`
  <div class="paid-line-item-container">
    <d2l-icon icon="tier3:check-circle" class="green-icon"></d2l-icon>
    <financial-details-line-item
      class="paid-line-item"
      bold
      .amount=${amount}
      .description=${description}>
    </financial-details-line-item>
  </div>
  `;
};

export const taxLineItem = ({
  isActivity,
  isDraft,
  isTaxable,
}) => {
  const taxText = isActivity
    ? 'activity-fees.taxWillApply'
    : isDraft
      ? 'activity-fees.taxCalculatedNext'
      : 'activity-fees.taxIncluded';

  return isTaxable
    ? html`<financial-details-line-item amount="${novaLocalize(taxText)}"></financial-details-line-item>`
    : nothing;
};

// Footer sub-template
export const howToPayFooter = (
  companyName,
  provider,
  skeletonState) => {

  const langTerm = provider?.isSubmitMyOwnProvider ? 'activity-fees.outOfPocket' : 'activity-fees.billedDirectly';
  if (skeletonState.footerContent || !provider?.id) return skeletonFooterContent();
  return novaLocalize(langTerm, { companyName });
};

export const refundContent = (application, tenant) => {
  return html`
    <p class="d2l-body-compact">
      ${novaLocalize('activity-fees.refundAmount', { refundPercent: application.refundPct, employerName: tenant.name })}
    </p>
  `;
};

export const stripeRefundContent = application => {
  return html`
    <p class="d2l-body-compact no-bottom-margin">
      ${novaLocalize('activity-fees.stripeRefundAmount', { refundPercent: application.refundPct })}
    </p>
  `;
};

// view-activity full templates
export const viewCourseTemplate = ({
  activity,
  companyName,
  editable,
  formattedActivityCost,
  formattedAvailableCredit,
  formattedAvailableCreditInProviderCurrency,
  formattedEmployeeCost,
  isActivityPopulated,
  isApplicationStatusPending,
  isCorporatePolicy,
  isTooltipVisible,
  provider,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {
  if (!activity.hasTag('allowRequest')) return nothing;

  const companyLineItemProps = {
    formattedCompanyCost: formattedAvailableCredit,
    formattedCompanyCostInProviderCurrency: formattedAvailableCreditInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isBold: false,
    isTooltipVisible,
    providerOperatingCurrency,
    useCoverageComponent: true,
    tenant,
  };

  const employeeLineItemProps = {
    formattedEmployeeCost,
    isActivityPopulated,
    isBold: true,
    learnerTerminology: tenant.learnerTerminology,
  };

  const activityPriceLineItemProps = {
    activity,
    editable,
    formattedActivityCost,
    isActivityPopulated,
    saveCostHandler,
    skeletonState,

    // tax
    isActivity: true,
  };

  const taxLineItemProps = {
    isActivity: true,
    isDraft: false,
    isTaxable: activity.hasTag('taxable'),
  };

  const primaryContentHtml = html`
    <div slot="primary">
      ${activityPriceLineItem(activityPriceLineItemProps)}
      ${isCorporatePolicy ? companyLineItem(companyLineItemProps) : nothing}
      ${isCorporatePolicy ? nothing : taxLineItem(taxLineItemProps)}
    </div>`;

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : primaryContentHtml;

  const secondaryContent = skeletonState.content
    ? nothing
    : isCorporatePolicy
      ? html`
      <div slot="secondary">
        ${employeeLineItem(employeeLineItemProps)}
        ${taxLineItem(taxLineItemProps)}
      </div>`
      : nothing;

  const footerContent = tenant.learnerTerminology === 'employee' ? html`
    <div slot="footer">
      ${howToPayFooter(companyName, provider, skeletonState)}
    </div>
  ` : nothing;

  return html`
    ${primaryContent}
    ${secondaryContent}
    ${footerContent}
  `;
};

export const viewProgramTemplate = ({
  activity,
  childActivityCosts,
  childCourses,
  companyName,
  editable,
  formattedActivityCost,
  formattedAvailableCredit,
  formattedAvailableCreditInProviderCurrency,
  formattedEmployeeCost,
  isActivityPopulated,
  isApplicationStatusPending,
  isCorporatePolicy,
  isTooltipVisible,
  provider,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {

  const isCourseRequestable = childCourses?.length ? childCourses?.map(course => {
    return course?.tags?.tagValues.includes('allowRequest');
  }).every(hasAllowRequest => hasAllowRequest === true) : false;

  // if child courses are not requestable, we know the program is the only thing that can be requested - can show breakdown

  const isLoneRequestable = !!(activity.hasTag('allowRequest')
    && (!isCourseRequestable));

  const companyLineItemProps = {
    formattedCompanyCost: formattedAvailableCredit,
    formattedCompanyCostInProviderCurrency: formattedAvailableCreditInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isBold: false,
    isTooltipVisible,
    providerOperatingCurrency,
    useCoverageComponent: true,
    tenant,
  };

  const employeeLineItemProps = {
    formattedEmployeeCost,
    isActivityPopulated,
    isBold: true,
    learnerTerminology: tenant.learnerTerminology,
  };

  const activityPriceLineItemProps = {
    activity,
    editable,
    formattedActivityCost,
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };

  const taxLineItemProps = {
    isActivity: true,
    isDraft: false,
    isTaxable: activity.hasTag('taxable'),
  };

  const htmlTemplate = html`
    <div slot="primary">
      ${activityPriceLineItem(activityPriceLineItemProps)}
      ${childActivitiesLineItem(childActivityCosts, childCourses)}
      ${isCorporatePolicy ? companyLineItem(companyLineItemProps) : nothing}
      ${isCorporatePolicy ? nothing : taxLineItem(taxLineItemProps)}
    </div>`;

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : htmlTemplate;

  const secondaryContent = skeletonState.content
    ? nothing
    : isCorporatePolicy && isLoneRequestable
      ? html`
          <div slot="secondary">
            ${employeeLineItem(employeeLineItemProps)}
            ${taxLineItem(taxLineItemProps)}
          </div>`
      : isCorporatePolicy && !!activity.hasTag('allowRequest') ? html`<div slot="secondary">${taxLineItem(taxLineItemProps)}</div>` : nothing;
  const footerContent = tenant.learnerTerminology === 'employee' ? html`
    <div slot="footer">
      ${howToPayFooter(companyName, provider, skeletonState)}
    </div>
  ` : nothing;

  return html`
    ${primaryContent}
    ${secondaryContent}
    ${footerContent}
  `;
};

// view-application paid templates when viewing on employer tenant site
export const employerTenantPaidTemplate = ({
  activity,
  application,
  editable,
  formattedActivityCostWithTax,
  formattedCompanyCost,
  formattedCompanyCostInProviderCurrency,
  formattedEmployeeCost,
  isActivityPopulated,
  isApplicationStatusPending,
  isTooltipVisible,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {

  const companyLineItemProps = {
    application,
    formattedCompanyCost,
    formattedCompanyCostInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isBold: false,
    isTooltipVisible,
    providerOperatingCurrency,
    useCoverageComponent: true,
    tenant,
  };

  const activityPriceLineItemProps = {
    activity,
    editable,
    formattedActivityCost: formattedActivityCostWithTax,
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };

  const paidLineItemProps = {
    formattedPaidAmount: formattedEmployeeCost,
    isActivityPopulated,
  };

  const taxLineItemProps = {
    isActivity: false,
    isDraft: false,
    isTaxable: application.taxable,
  };

  // todo
  const primaryContent = skeletonState.content
    ? skeletonContent()
    : html`
      <div slot="primary">
        ${activityPriceLineItem(activityPriceLineItemProps)}
        ${companyLineItem(companyLineItemProps)}
      </div>`;

  const secondaryContent = skeletonState.content
    ? nothing
    : html`
      <div slot="secondary">
        ${paidLineItem(paidLineItemProps)}
        ${taxLineItem(taxLineItemProps)}
      </div>`;

  return html`
    ${primaryContent}
    ${secondaryContent}
  `;
};

// view-application full templates
export const basicDetailsTemplate = ({
  activity,
  companyName,
  editable,
  formattedActivityCostWithTax,
  formattedCompanyCost,
  formattedCompanyCostInProviderCurrency,
  formattedEmployeeCost,
  isActivityPopulated,
  isApplicationStatusApproved,
  isApplicationStatusDeclined,
  isApplicationStatusPending,
  isApplicationTaxable,
  isRequestor,
  isTooltipVisible,
  provider,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {

  const companyLineItemProps = {
    formattedCompanyCost,
    formattedCompanyCostInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isBold: false,
    isTooltipVisible,
    providerOperatingCurrency,
    useCoverageComponent: true,
    tenant,
  };

  const employeeLineItemProps = {
    formattedEmployeeCost,
    isActivityPopulated,
    isBold: true,
    learnerTerminology: tenant.learnerTerminology,
  };

  const activityPriceLineItemProps = {
    activity,
    editable,
    formattedActivityCost: formattedActivityCostWithTax,
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };

  const taxLineItemProps = {
    isActivity: false,
    isDraft: false,
    isTaxable: isApplicationTaxable,
  };

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : html`
      <div slot="primary">
        ${activityPriceLineItem(activityPriceLineItemProps)}
        ${!isApplicationStatusDeclined ? companyLineItem(companyLineItemProps) : nothing}
      </div>`;

  const secondaryContent = skeletonState.content
    ? nothing
    : html`
      <div slot="secondary">
        ${employeeLineItem(employeeLineItemProps)}
        ${taxLineItem(taxLineItemProps)}
      </div>`;

  const footerContent = isRequestor && (isApplicationStatusPending || isApplicationStatusApproved || isApplicationStatusDeclined)
    ? (tenant.learnerTerminology === 'employee' ? html`
          <div slot="footer">
            ${howToPayFooter(companyName, provider, skeletonState)}
          </div>
        ` : nothing)
    : nothing;

  return html`
    ${primaryContent}
    ${secondaryContent}
    ${footerContent}
  `;
};

export const pendingSubmitMyOwnTemplate = ({
  activity,
  activityCost,
  editable,
  formattedActivityCost,
  formattedActivityCostWithTax,
  isActivityPopulated,
  isDraft = false,
  saveCostHandler,
  skeletonState,
  isRequestor,
  application,
  tenant,
  provider,
  companyName,
}) => {
  const activityPriceLineItemProps = {
    activity,
    activityCost,
    editable,
    formattedActivityCost: (isDraft ? formattedActivityCost : formattedActivityCostWithTax),
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };
  const hasRequestor = !!application.user.userId;

  const primaryContentHtml = html`
    <div slot="primary">
      ${activityPriceLineItem(activityPriceLineItemProps)}
    </div>`;

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : primaryContentHtml;

  const footerContent = !hasRequestor || isRequestor
    ? (tenant.learnerTerminology === 'employee' ? html`
        <div slot="footer">
          ${howToPayFooter(companyName, provider, skeletonState)}
        </div>
      ` : nothing)
    : nothing;

  return html`
    ${primaryContent}
    ${footerContent}
  `;
};

export const applicationPendingTemplate = ({
  activity,
  activityCost,
  application,
  applying,
  companyName,
  editable,
  formattedActivityCost,
  formattedActivityCostWithTax,
  formattedCompanyCost,
  formattedCompanyCostInProviderCurrency,
  formattedEmployeeCost,
  isActivityPopulated,
  isApplicationStatusPending,
  isCorporatePolicy,
  isDraft = false,
  isRequestor,
  isTooltipVisible,
  provider,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {
  const companyLineItemProps = {
    formattedCompanyCost,
    formattedCompanyCostInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isBold: false,
    isTooltipVisible,
    providerOperatingCurrency,
    useCoverageComponent: true,
    tenant,
  };

  const employeeLineItemProps = {
    formattedEmployeeCost,
    isActivityPopulated,
    isBold: true,
    learnerTerminology: tenant.learnerTerminology,
  };

  const activityPriceLineItemProps = {
    activity,
    activityCost,
    editable,
    formattedActivityCost: (isDraft ? formattedActivityCost : formattedActivityCostWithTax),
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };

  const taxLineItemProps = {
    isActivity: false,
    isDraft,
    isTaxable: applying ? activity.hasTag('taxable') : application.taxable,
  };

  const hasRequestor = !!application.userId;

  const primaryContentHtml = html`
    <div slot="primary">
      ${activityPriceLineItem(activityPriceLineItemProps)}
      ${isCorporatePolicy ? companyLineItem(companyLineItemProps) : nothing}
      ${(isCorporatePolicy && !isDraft) ? nothing : taxLineItem(taxLineItemProps)}
    </div>`;

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : primaryContentHtml;

  const secondaryContent = (skeletonState.content || isDraft)
    ? nothing
    : isCorporatePolicy
      ? html`
        <div slot="secondary">
          ${isDraft ? nothing : employeeLineItem(employeeLineItemProps)}
          ${!isDraft ? taxLineItem(taxLineItemProps) : nothing}
        </div>`
      : nothing;

  const footerContent = !hasRequestor || isRequestor
    ? (tenant.learnerTerminology === 'employee' ? html`
          <div slot="footer">
            ${howToPayFooter(companyName, provider, skeletonState)}
          </div>
        ` : nothing)
    : nothing;

  return html`
    ${primaryContent}
    ${secondaryContent}
    ${footerContent}
  `;
};

export const cancelWithdrawnCaseByCaseTemplate = subTemplates => {
  return html`
      <div slot="primary">
        ${subTemplates.stripeRefundContent}
      </div>
    `;
};

export const cancelWithdrawnTemplate = subTemplates => {
  return html`
      <div slot="primary">
        ${subTemplates.stripeRefundContent}
      </div>
    `;
};

export const academicProviderTemplate = ({
  activity,
  application,
  childActivityCosts,
  childCourses,
  editable,
  formattedActivityCostWithTax,
  formattedCompanyCost,
  formattedCompanyCostInProviderCurrency,
  formattedEmployeeCost,
  hasRequestor,
  isActivity,
  isActivityPopulated,
  isApplicationProviderVisible,
  isApplicationStatusPending,
  isDraft,
  isTooltipVisible,
  providerOperatingCurrency,
  saveCostHandler,
  skeletonState,
  tenant }) => {

  const companyLineItemProps = {
    formattedCompanyCost,
    formattedCompanyCostInProviderCurrency,
    isActivityPopulated,
    isApplicationStatusPending,
    isTooltipVisible,
    providerOperatingCurrency,
    tenant,
  };

  const employeeLineItemProps = {
    formattedEmployeeCost,
    isActivityPopulated,
    learnerTerminology: tenant.learnerTerminology,
  };

  const activityPriceLineItemProps = {
    activity,
    editable,
    formattedActivityCost: formattedActivityCostWithTax,
    isActivityPopulated,
    saveCostHandler,
    skeletonState,
  };

  const paidLineItemProps = {
    formattedPaidAmount: formattedActivityCostWithTax,
    isActivityPopulated,
  };

  const isTaxable = application ? application.taxable : activity.hasTag('taxable');
  const taxLineItemProps = {
    isActivity,
    isDraft,
    isTaxable,
  };

  const costBreakdown = hasRequestor && isApplicationProviderVisible
    ? html`
  ${companyLineItem(companyLineItemProps)}
  ${employeeLineItem(employeeLineItemProps)}
  ` : nothing;

  const childCourseCosts = activity.type === 'program'
    ? childActivitiesLineItem(childActivityCosts, childCourses)
    : nothing;

  const showStripeRefundContent = application.refundPct
  && (application.isWithdrawn || application.isCancelled);

  let loadedPrimaryContent;

  if (showStripeRefundContent) {
    loadedPrimaryContent =
      html`
        <div slot="primary">
          ${stripeRefundContent(application)}
        </div>
      `;
  } else if (application.isPaid) {
    loadedPrimaryContent =
      html`
        <div slot="primary">
          ${paidLineItem(paidLineItemProps)}
        </div>
      `;
  } else {
    loadedPrimaryContent =
      html`
        <div slot="primary">
          ${activityPriceLineItem(activityPriceLineItemProps)}
          ${childCourseCosts}
          ${costBreakdown}
          ${taxLineItem(taxLineItemProps)}
        </div>
      `;
  }

  const primaryContent = skeletonState.content
    ? skeletonContent()
    : loadedPrimaryContent;

  return html`${primaryContent}`;
};
