import '@brightspace-ui/core/components/button/button.js';

import { bodyStandardStyles, heading1Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../../components/general/suggest-activity/suggest-activity.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { spaNav } from '../../../../../shared/methods.js';

export default class ViewApplicationConfirmation extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _application: { type: Object, attribute: false },
      _provider: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyStandardStyles,
      heading1Styles,
      heading3Styles,
      css`
        :host {
          display: block;
          margin: 0 auto;
          width: 90vw;
        }

        .all-set-container-top {
          align-items: center;
          display: flex;
          flex-direction: wrap;
          justify-content: center;
          max-width: 1170px;
        }

        .all-set-container-bottom {
          align-items: center;
          display: flex;
          flex-direction: wrap;
          justify-content: center;
          max-width: 1170px;
        }

        .all-set-image {
          display: flex;
          justify-content: center;
          width: 35%;
        }

        .all-set-image img {
          height: auto;
          max-width: 100%;
          max-width: 411px;
          width: 100%;
        }

        .all-set-header {
          padding-left: 102px;
          width: 65%;
        }

        .all-set-content {
          align-self: flex-start;
          width: 63%;
        }

        .all-set-sidebar {
          align-self: flex-start;
          padding-left: 87px;
          width: 37%;
        }

        .all-set-heading {
          font-size: 40px;
          font-weight: 400;
          line-height: 57px;
        }

        .all-set-heading-mobile {
          display: none;
        }

        .suggest-form-input {
          padding-bottom: 1.2rem;
        }

        .context-image {
          display: block;
          margin: 90px auto 0;
          max-width: 196px;
          width: 100%;
        }

        .email {
          overflow-wrap: break-word;
        }

        .title {
          margin: 60px 0 30px;
        }

        .subtitle {
          font-size: 21px;
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 18px;
          margin-top: 16px;
        }

        .info {
          margin-bottom: 36px;
        }

        @media (max-width: 1000px) {
          .all-set-heading {
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
          }

          .all-set-content,
          .all-set-sidebar {
            margin-bottom: 40px;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
          }

          .all-set-container-bottom {
            flex-direction: column;
          }
        }

        @media (max-width: 880px) {
          .all-set-heading {
            display: none;
          }

          .all-set-heading-mobile {
            display: flex;
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 10px;
            margin-top: 10px;
          }

          .all-set-image {
            display: flex;
            justify-content: center;
            max-width: 310px;
            width: 100%;
          }

          .all-set-header {
            padding-left: 0;
            width: 100%;
          }

          .all-set-container-top {
            flex-direction: column;
          }
        }

        @media (max-width: 450px) {
          .all-set-image,
          .all-set-header {
            width: 100%;
          }

          .context-image {
            margin: 30px auto 0;
          }

          .subtitle {
            font-size: 20px;
            line-height: 30px;
          }
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.skeleton = true;
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    try {
      // Check if application status is in the desired state, otherwise redirect to /requests page
      this._application = await this.client.getApplication(this.params.uuid);
      this._activity = await this.client.fetchActivity(this._application.activity.id);
      this._provider = await this.client.fetchTenant(this._application.activity.provider);

      // The page should only be displayed if payment was already made, or if payment was made through stripe.
      let validConfirmationPage = false;

      if (this._application.paymentDate) {
        validConfirmationPage = true;
      } else if (this.query.session_id && this._application.stripeCheckoutSessionId) {
        validConfirmationPage = this._application.stripeCheckoutSessionId === this.query.session_id;
        if (validConfirmationPage) {
          this.client.logEvent({
            eventType: 'accountConnectionStepThreeComplete',
            providerId: this._application.activity.provider,
            providerName: this._provider.name,
            applicationUuid: this._application.uuid,
            activityTitle: this._application.activity.title,
          });
        }
      }

      if (!validConfirmationPage) {
        this.navigate('/requests');
      }

    } catch (e) {
      console.error(e);
      this.navigate('/requests');
    }
    this.skeleton = false;
  }

  render() {
    const admissionBased = this._application?.activity.hasTag('admissionBased');

    const subTitle = this.localize(`view-application-confirmation.subtitle${ admissionBased ? '.admissionBased' : ''}`, { institution: this._application?.institution, activityTitle: this._application?.activity.title });
    const info = this.localize(`view-application-confirmation.info${ admissionBased ? '.admissionBased' : ''}`, { institution: this._application?.institution, email: this._application?.user?.getEmail(), registrationTime: this._provider?.registrationTime, emailSpan: (...chunks) => html`<span class="email">${chunks}</span>` });
    const infoWait = this.localize(`view-application-confirmation.wait.info${ admissionBased ? '.admissionBased' : ''}`, { activityType: this._activity?.type, institution: this._application?.institution, registrationTime: this._provider?.registrationTime });

    return html`
      <div class="all-set-container-top">
        <div class="d2l-skeletize all-set-image">
          <img src="../../../../assets/img/all-set.png" alt="all-set-graphic">
        </div>
        <div class="all-set-header">
          <h1 class="d2l-skeletize d2l-heading-1 all-set-heading">${this.localize('view-application-confirmation.title', { linebreak: () => html`<br />` })}</h1>
          <h1 class="d2l-skeletize d2l-heading-1 all-set-heading-mobile">${this.localize('view-application-confirmation.title.mobile')}</h1>
        </div>
      </div>

      <div class="all-set-container-bottom">
        <div class="all-set-content">
          <h2 class="d2l-skeletize d2l-body-standard subtitle" style="font-weight: 700;">
            ${ subTitle }
          </h2>
          <div class="d2l-skeletize d2l-body-standard info">
            ${ info }
          </div>
          <div class="d2l-skeletize d2l-body-standard info">
            ${ infoWait }
          </div>
          <d2l-button
            class="d2l-skeletize"
            @click=${spaNav(this.navigate, `/requests/${this.params.uuid}`)}>
            ${this.localize('view-application-confirmation.backButton')}
          </d2l-button>
        </div>
        <div class="all-set-sidebar">
          <suggest-activity
            expanded
            isButtonPrimary
            .activity="${this._activity}"
            suggestOrigin="viewApplicationConfirmation"
            context="taking">
          </suggest-activity>
        </div>
      </div>
    `;
  }
}

window.customElements.define('view-application-confirmation', ViewApplicationConfirmation);
