/* eslint-disable sort-class-members/sort-class-members */
import { html } from 'lit';
import { Localizer } from '../../../../shared/l10n/localizers/localizer.js';

/**
 * This is the localizer for the frontend. It's responsible for loading the language based on the browser setting.
 *
 * NOTE: When using the localizer in the frontend it will pull the language from the browser settings.
 * If you pass the language to the localize call it may not work if that language is not one of the loaded languages. (This usage pattern is extremely unlikely for the frontend)
 */
export class FrontendLocalizer extends Localizer {

  constructor(lang) {
    super();
    this.__language = lang;
    this.init();
  }

  addTranslations(translations, language = 'en') {
    for (const key of Object.keys(translations)) {
      Localizer.resources[key] = { language, value: translations[key] };
    }
  }

  async getLocalizeResources(lang) {
    let translations;
    switch (lang) {
      case 'ar':
        translations = await import('../../../../shared/lang/ar.js');
        break;
      case 'cy':
        translations = await import('../../../../shared/lang/cy.js');
        break;
      case 'da':
        translations = await import('../../../../shared/lang/da.js');
        break;
      case 'de':
        translations = await import('../../../../shared/lang/de.js');
        break;
      case 'en':
        translations = await import('../../../../shared/lang/en.js');
        break;
      case 'es-es':
        translations = await import('../../../../shared/lang/es-es.js');
        break;
      case 'es':
        translations = await import('../../../../shared/lang/es.js');
        break;
      case 'fr-fr':
        translations = await import('../../../../shared/lang/fr-fr.js');
        break;
      case 'fr':
        translations = await import('../../../../shared/lang/fr.js');
        break;
      case 'ja':
        translations = await import('../../../../shared/lang/ja.js');
        break;
      case 'ko':
        translations = await import('../../../../shared/lang/ko.js');
        break;
      case 'nl':
        translations = await import('../../../../shared/lang/nl.js');
        break;
      case 'pt':
        translations = await import('../../../../shared/lang/pt.js');
        break;
      case 'sv':
        translations = await import('../../../../shared/lang/sv.js');
        break;
      case 'tr':
        translations = await import('../../../../shared/lang/tr.js');
        break;
      case 'zh-tw':
        translations = await import('../../../../shared/lang/zh-tw.js');
        break;
      case 'zh':
        translations = await import('../../../../shared/lang/zh.js');
        break;
    }
    if (translations && translations.default) {
      return {
        language: lang,
        resources: translations.default,
      };
    }
    translations = await import('../../../../shared/lang/en.js');
    return {
      language: 'en',
      resources: translations.default,
    };
  }

  init() {
    Localizer.__resourcesLoadedPromise = new Promise(resolve => {
      const possibleLanguages = this._generatePossibleLanguages();
      const localizeResources = possibleLanguages.map(this.getLocalizeResources);
      const resourcesLoadedPromise = Promise.all(localizeResources);
      resourcesLoadedPromise
        .then(results => {
          if (results.length === 0) {
            return;
          }
          const resources = {};
          for (const res of results) {
            const language = res.language;
            for (const [key, value] of Object.entries(res.resources)) {
              resources[key] = resources[key] || { language, value };
            }
          }
          Localizer.resources = resources;
          resolve();
        });
    });
  }

  get _richTextTagParams() {
    return {
      b: chunks => html`<strong>${chunks}</strong>`,
      bold: chunks => html`<strong>${chunks}</strong>`,
    };
  }

  localize(key, params) {
    if (!key || !Localizer.resources) {
      return '';
    }
    const langTermValue = Localizer.resources[key] ? Localizer.resources[key].value : undefined;

    if (!langTermValue) {
      return '';
    }

    const combinedParams = {
      ...this._richTextTagParams,
      ...params,
    };

    return this._formatLocalizedMessage(langTermValue, combinedParams, this.__language);
  }

  _generatePossibleLanguages() {
    const langs = new Set();
    const addLang = lang => {
      if (lang) {
        lang = lang.toLowerCase();
        langs.add(lang);

        if (lang.indexOf('-') !== -1) {
          const baseDocLang = lang.split('-')[0];
          langs.add(baseDocLang);
        }
      }
    };

    addLang(this.__language);
    langs.add('en');

    return Array.from(langs);
  }

}
