import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog-confirm.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { ActiveLinkMixin } from '../../../../mixins/active-link/active-link-mixin.js';
import { LocalizeNova } from '../../../../mixins/localize-nova/localize-nova.js';
import { novaTableStyles } from '../../../../../main/components/applications/application-table/styles.js';

/**
 * Shows a single activity table row
 */
export default class ActivitiesTableRow extends ActiveLinkMixin(LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      provider: { type: Object, attribute: false },
      activity: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      novaTableStyles,
      selectStyles,
      css`
        .submit-buttons {
          padding-top: 10px;
        }

        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .action-filter {
          margin-bottom: 10px;
        }

        .message {
          height: 200px;
        }

        .provider-logo {
          height: auto;
          max-height: 30px;
          max-width: 100%;
          width: auto;
        }

        .table-row, .table-header {
          grid-template-columns: 100px 200px 100px auto 100px 100px 50px;
          padding: 5px 20px;
        }

        .table-item {
          align-items: center;
          display: flex;
        }

        .requestable {
          margin: auto;
        }

        .initiator {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60px;
        }
`,
    ];
  }

  get href() {
    return `/activities/${this.activity?.id}/edit`;
  }

  connectedCallback() {
    super.connectedCallback();
    this._providerMap = {};
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    const hasNoCategory = this.activity?.category?.tagValues?.length === 0;
    const isSwitchDisabled = !this.activity?.hasTag('active') && (hasNoCategory || this.activity.type === 'course');
    const tooltipMessage = hasNoCategory ? 'edit-activity.switch.tooltip.category' : 'edit-activity.switch.tooltip.disabled';

    return this.wrapWithLink(html`
        <div class="table-row d2l-skeletize" role="row">
          <div class="table-item active" role="cell">
          <d2l-switch
            ?disabled=${isSwitchDisabled}
            id="active"
            @click="${this.stopProp}"
            @change="${this.changeActiveState}"
            .on="${this.activity?.hasTag('active')}"
            text="${this.localize('edit-activity.active.checkbox')}"
            text-position="hidden">
          </d2l-switch>
          ${isSwitchDisabled ? html`<d2l-tooltip for="active">${this.localize(tooltipMessage)}</d2l-tooltip>` : nothing}
          </div>
          <div class="table-item provider" role="cell"><img class="provider-logo" alt="${this.provider?.name}" src="${ifDefined(this.provider?.imageUrl)}"></div>
          <div class="table-item" role="cell"><d2l-status-indicator class="activity-type" state="none" text="${this.activity?.getTranslatedValue('type')}" bold></d2l-status-indicator></div>
          <div class="table-item action" role="cell">${this.activity?.title}</div>
          <div class="table-item requestable" role="cell">
            ${this.activity?.hasTag('allowRequest') ? html`<d2l-icon id="requestable" text="Requestable" icon="tier1:check"></d2l-icon>` : nothing}
          </div>
          <div class="table-item taxable" role="cell">
            ${this.activity?.hasTag('taxable') ? html`<d2l-icon id="taxable" text="Taxable" icon="tier1:check"></d2l-icon>` : nothing}
          </div>
          <div class="table-item actions" role="cell">
          <d2l-button-icon id="view-activity" text="${this.localize('general.view')}" icon="tier1:search" @click=${this._viewActivity}></d2l-button-icon>
          </div>
        </div>
    `);
  }

  changeActiveState(e) {
    this.stopProp(e);
    this.dispatchEvent(new CustomEvent('nova-activity-state-change', {
      detail: { activity: this.activity },
      bubbles: true,
      composed: true,
    }));
  }

  stopProp(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  _viewActivity(e) {
    e.preventDefault();
    this.navigate(`/activities/${this.activity?.id}`);
  }

}

window.customElements.define('activity-table-row', ActivitiesTableRow);
