import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import './skill-chip/skill-chip.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class SkillChipList extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      skills: { type: Array },
      removable: { type: Boolean },
      maxSkills: { type: Number, attribute: 'max-skills', reflect: true },
      stopWrap: { type: Boolean, attribute: 'stop-wrap' },
      truncateSkillText: { type: Boolean, attribute: 'truncate-skill-text' },
    };
  }

  static get styles() {
    return [
      css`
        .skill-list {
          display: flex;
          gap: 6px;
          margin: 0;
          padding: 0;
        }

        .skill-list-item {
          display: inline-block;
        }
`,
    ];
  }

  constructor() {
    super();
    this.maxSkills = null;
    this.removable = false;
    this.skills = null;
    this.truncateSkillText = false;
  }

  render() {
    if (!this.skills || this.skills.length === 0) return nothing;

    const stopWrapStyling = this.stopWrap ? 'flex-flow: nowrap;' : 'flex-flow: row wrap;';

    return this.maxSkills === 0
      ? html`
          <ul class="skill-list" style="${stopWrapStyling}" aria-label=${this.localize('general.skills')}>
            ${this._countOnlyTemplate}
          </ul>`
      : html`
          <ul class="skill-list" style="${stopWrapStyling}" aria-label=${this.localize('general.skills')}>
            ${this._chipListTemplate}
            ${this._footerChipTemplate}
          </ul>
        `;
  }

  get _chipListTemplate() {
    return repeat(this._skills,
      skill => skill.id,
      skill => html`
        <li class="skill-list-item">
          <skill-chip .skill=${skill} ?removable=${this.removable} ?truncate=${this.truncateSkillText}></skill-chip>
        </li>
      `
    );
  }

  get _countOnlyTemplate() {
    const displayString = this.localize('skill-chip-list.numSkills', { number: this.skills.length });

    return html`
      <li class="skill-list-item">
        <skill-chip .text=${displayString}></skill-chip>
      </li>
    `;
  }

  get _footerChipTemplate() {
    const displayString = ['+', this._remainder].join('');

    return this._remainder
      ? html`
          <li class="skill-list-item">
            <skill-chip id="footer-chip" .text=${displayString} .titleText=${this._remainingSkillsString} remainder></skill-chip>
          </li>`
      : nothing;
  }

  get _remainder() {
    return Math.max(0, this.skills.length - this._skills.length);
  }

  get _skills() {
    const sortedSkills = this.sortSkills(this.skills);
    return sortedSkills.slice(0, this.maxSkills || this.skills.length);
  }

  // Get skills that are not displayed as a chip but rather part of the remainder
  get _remainingSkills() {
    if (this.skills.length <= this.maxSkills) return [];
    return this.skills.slice(this.maxSkills);
  }

  get _remainingSkillsString() {
    return this._remainingSkills.map(s => s.name).join(', ');
  }

  sortSkills(skills) {
    function calculateImportance(skill) {
      let importance = 0;

      if (skill?.isInSkillProfile) {
        if (skill?.isInDemand) {
          importance = 4;
        } else {
          importance = 3;
        }
      } else if (skill?.isInDemand) {
        importance = 2;
      }

      if (importance === 0 && skill?.isRelatedToRole) {
        importance = 1;
      }

      return importance;
    }

    return skills.sort((a, b) => {
      const aImportance = calculateImportance(a);
      const bImportance = calculateImportance(b);

      if (aImportance === bImportance) {
        // Sort by name if importance is the same
        return a.name.localeCompare(b.name);
      }

      return bImportance - aImportance;
    });
  }
}

window.customElements.define('skill-chip-list', SkillChipList);
