import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';

import '../../general/detail-list/detail-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { getTemplateData } from './templates.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class ConfirmationDialog extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      data: { type: Object, reflect: false },
      disableConfirm: { type: Boolean, reflect: true },
      isDoNotShowChecked: { type: Boolean, attribute: false },
      opened: { type: Boolean, reflect: true },
      type: { type: String },
      displayNotificationToast: { type: Object, reflect: false },
      _templateData: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
        .action-message {
          margin: 0;
        }

        .description {
          margin: 0;
        }

        .dialog-attributes {
          padding: 20px 0;
        }
`,
    ];
  }

  constructor() {
    super();
    this.opened = false;
    this.disableConfirm = false;
    this.isDoNotShowChecked = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    return html`
      <d2l-dialog
        title-text=${this._title}
        ?opened=${this.opened}
        @d2l-dialog-before-close=${this._handleDialogBeforeClose}
        @d2l-dialog-close=${this._handleDialogClose}>
        ${this._description}
        ${this._content}
        ${this._postContentMessage}
        <slot name="below-content"></slot>
        ${this._footerDescription}
        ${this._actionMessage}
        <d2l-button slot="footer" primary data-dialog-action=${this._approveButtonAction} ?disabled=${this.disableConfirm}>${this._approveButtonText}</d2l-button>
        <d2l-button slot="footer" data-dialog-action="abort">${this._cancelButtonText}</d2l-button>
        ${this._doNotShowAgain}
      </d2l-dialog>
    `;
  }

  updated(_changedProperties) {
    const localize = (input, params) => this.localize(input, params);
    for (const [propName] of _changedProperties) {
      if (propName === 'data') {
        this._templateData = getTemplateData(this.type, this.data, localize);
      }
    }
  }

  get _actionMessage() {
    return this._templateData?.actionMessage ? html`<p class="action-message"><b>${this.localize(this._templateData?.actionMessage)}</b></p>` : nothing;
  }

  get _approveButtonAction() {
    return this._templateData?.button?.action || 'done';
  }

  get _approveButtonText() {
    return this.localize(this._templateData?.button?.text) || this.localize('confirmation-dialog.confirm');
  }

  get _cancelButtonText() {
    return this._templateData?.cancelButton?.text ? this.localize(this._templateData.cancelButton.text) : this.localize('confirmation-dialog.cancel');
  }

  get _content() {
    if (this._templateData?.content?.length > 0) {
      const localizedContent = this._localizeContent();

      return html`
      <div class="dialog-attributes">
        <detail-list .data=${localizedContent} dialog-spacing></detail-list>
      </div>`;
    } else {
      return nothing;
    }
  }

  get _description() {
    return this._templateData?.description ? html`<p class="description">${this._templateData.description}</p>` : nothing;
  }

  get _doNotShowAgain() {
    return this._templateData?.doNotShowDialog ? html`
      <d2l-input-checkbox
        slot="footer"
        ?checked=${this.isDoNotShowChecked}
        @change=${this._onChangeDoNotShowAgain}>
        ${this.localize('confirmation-dialog.doNotShowAgain')}
      </d2l-input-checkbox>
    ` : nothing;
  }

  get _footerDescription() {
    return this._templateData?.footerDescription ? html`<p class="footer-description">${this.localize(this._templateData.footerDescription)}</p>` : nothing;
  }

  get _postContentMessage() {
    return this._templateData?.postContentMessage ? html`<p class="footer-description">${this.localize(this._templateData.postContentMessage)}</p>` : nothing;
  }

  get _title() {
    return this.localize(this._templateData?.title) || '';
  }

  async _handleDialogBeforeClose(e) {
    const { action } = e.detail;
    if (this.type === 'approveRequestConfirmation' && action === 'approve' && ((this.session.tenant.approvalModel === 'hybrid' && this.session.tenant?.budget?.type !== 'corporatePolicy' && !this.data.approvedAmountConfirmed) || this.data.isOverAmount || this.data.isBelowAmount)) {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent('d2l-dialog-before-close', {
        bubbles: true,
        composed: true,
      }));
    }
    const { doNotShowDialog } = this._templateData;
    if (action !== 'abort' && doNotShowDialog && this.isDoNotShowChecked) {
      await this.client.setSessionSetting(doNotShowDialog, true);
    }
    if (action === 'done' && this.displayNotificationToast) {
      this.displayNotificationToast();
    }
  }

  _handleDialogClose() {
    this.opened = false;
  }

  _localizeContent() {
    return this._templateData?.content.map(item => {
      return { key: this.localize(item.key) || item.key, value: item.value, opts: item.opts };
    });
  }

  _onChangeDoNotShowAgain(e) {
    this.isDoNotShowChecked = e.target.checked;
  }
}

window.customElements.define('confirmation-dialog', ConfirmationDialog);
