import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../nova-carousel/nova-carousel.js';
import Activity from '../../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../../shared/models/activity-filter/activity-filter.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class TakeActionTodayCarousel extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {
  static get styles() {
    return [
      super.styles,
      css`
        .empty-state-container {
          border: 2px solid #e6eaf0;
          border-radius: 12px;
          box-shadow: 2px 2px 10px 2px #0000000d;
          box-sizing: border-box;
          display: block;
          padding: 30px 60px;
          position: relative;
          width: 100%;
        }
`,
    ];
  }

  static get properties() {
    return {
      _tabsContent: { type: Array },
      _errorState: { type: Boolean },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    try {
      const [continueYourProgramActivities, startingSoonActivities, visibleActivities] = await Promise.all([
        this.client.callWithRetries(() => this.client.getInProgressActivities()),
        this.client.callWithRetries(() => this.client.searchActivities({
          from: 0,
          size: 999,
          filter: new ActivityFilter(),
          sort: {
            sortProperty: 'startDate',
            min: new Date(Date.now()).toISOString(),
            max: new Date(Date.now() + 1669895858).toISOString(), // 2 weeks in milliseconds
            direction: 'asc',
          },
        }).then(({ hits }) => hits.map(hit => new Activity(hit)))),
        this.client.callWithRetries(() => this.client.activityList()),
      ]);

      const myListActivities = [], onDemandActivities = [];
      const myList = new Set(this.session?.settings?.myList ?? []);

      visibleActivities?.map(activity => {
        if (myList.has(activity.id)) {
          myListActivities.push(activity);
        }

        if (activity.startDateType === 'anytime') {
          onDemandActivities.push(activity);
        }
      });

      const tabContent = [];
      if (continueYourProgramActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.continueYourProgramTab'),
          content: continueYourProgramActivities,
          totalActivitiesInList: continueYourProgramActivities.length,
          // TODO: Eventually we want this to have a path param similar to the streams,
          // but we will need to build a new page and update the carousel to link to other areas as well
        });
      }

      if (startingSoonActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.startingSoonTab'),
          content: startingSoonActivities,
          totalActivitiesInList: startingSoonActivities.length,
          path: 'starting-soon',
        });
      }

      if (myListActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.myListTab'),
          content: myListActivities,
          totalActivitiesInList: myListActivities.length,
          path: 'myList',
        });
      }

      if (onDemandActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.onDemandTab'),
          content: onDemandActivities,
          totalActivitiesInList: onDemandActivities.length,
          path: 'on-demand',
        });
      }

      this._tabsContent = [...tabContent];
    } catch (error) {
      this._errorState = true;
    }

    this.skeleton = false;
  }

  constructor() {
    super();
    this.skeleton = true;
    this._tabsContent = [];
    this._errorState = false;
  }

  get _emptyStateTemplate() {
    return html`
      <div class="empty-state-container">
        <d2l-empty-state-illustrated description=${this.localize('view-landing-page.carousel.error.description')} title-text=${this.localize('general.error')}>
          <img aria-hidden="true" src="../../assets/img/error-state-search.svg" slot="illustration">
          <d2l-empty-state-action-button @d2l-empty-state-action=${this._handleEmptyState} text=${this.localize('view-landing-page.carousel.error.action')}></d2l-empty-state-action-button>
        </d2l-empty-state-illustrated>
      </div>
    `;
  }

  render() {
    if (!this.skeleton && this._tabsContent.length === 0 && !this._errorState) {
      return nothing;
    } else if (this.skeleton) {
      return html`<nova-carousel card-type="activity-hero-card" heading=${this.localize('general.loading')} max-cards-per-slide="1" skeleton></nova-carousel>`;
    } else if (this._errorState) {
      return this._emptyStateTemplate;
    }

    return html`
      <nova-carousel
        card-type="activity-hero-card"
        heading=${this.localize('take-action-today-carousel.heading')}
        .tabsContent=${this._tabsContent}
        ?skeleton=${this.skeleton}
      >
      </nova-carousel>
    `;
  }

  _handleEmptyState() {
    location.reload();
  }
}

customElements.define('take-action-today-carousel', TakeActionTodayCarousel);
