import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-main-footer.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from './shared/mixins/localize-nova/localize-nova.js';

import '../app/app-link.js';

class AppFooter extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      noMargin: { type: Boolean, attribute: 'no-margin', reflect: true },
      _programPolicy: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      linkStyles,
      css`
        :host {
          background-color: var(--d2l-color-sylvite);
          margin-top: 120px;
        }

        :host([no-margin]) {
          margin-top: 0;
        }

        .main-container {
          display: grid;
          grid-template-areas:
            "  details    logo "
            " copyright   logo ";
          grid-template-columns: auto 300px;
          grid-template-rows: auto auto;
          height: fit-content;
          max-width: 1170px;
          padding: 0.5rem 0;
        }

        .details-container {
          align-items: flex-end;
          display: flex;
          grid-area: details;
          padding: 0 0 0.8rem 0;
        }

        .program-policy,
        .contact-support {
          align-items: center;
          display: flex;
          margin: 0;
        }

        .program-policy {
          padding-right: 3rem;
        }

        .inline-icon {
          padding-right: 0.5rem;
          width: 24px;
        }

        .copyright-container {
          font-size: 0.6rem;
          grid-area: copyright;
          line-height: 0.8rem;
          margin: 0;
        }

        .d2l-body-small {
          color: var(--d2l-color-ferrite);
        }

        .logo-container {
          grid-area: logo;
        }

        @media (max-width: 767px) {
          .main-container {
            grid-template-areas:
              "  details  "
              "   logo    "
              " copyright ";
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            padding: 1rem 0.25rem;
          }

          .details-container {
            align-items: initial;
            flex-direction: column;
            padding: 0;
            row-gap: 0.25rem;
          }

          .program-policy,
          .contact-support {
            align-items: center;
          }

          .logo-container img {
            height: 75px;
            margin: -12px;
          }
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    if (this.session.tenantId) {
      const tenant = await this.client.fetchTenant(this.session.tenantId);
      this._programPolicy = tenant.programPolicy;
    }
  }

  render() {
    return this.session.loggedIn
      ? html`
        <footer>
          <d2l-navigation-main-footer>
            <div class="main-container" slot="main">
              <div class="details-container">
                ${this._programPolicyTemplate}
                ${this._contactSupportTemplate}
              </div>
              ${this._copyrightTemplate}
              <div class="logo-container">
                ${this.session.tenant?.hasFeature('marketing') ? html`
                  <img alt='D2L black logo' style="height: 80px; padding: 35px 12px;" src="assets/img/D2L-preferred-Black.svg"/>
                ` : html`<img alt='D2L Wave' height="150" src="assets/img/d2l-wave.svg"/>`}
              </div>
            </div>
          </d2l-navigation-main-footer>
        </footer>`
      : nothing;
  }

  get _contactSupportTemplate() {
    if (this.session.isPublicPortalGuest) return nothing;
    return html`
      <div class="contact-support d2l-body-small">
        <d2l-icon-custom class="inline-icon" size="tier3">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.926" height="17.202" viewBox="0 0 29.926 17.202">
            <path id="Path_291" data-name="Path 291" d="M.949-61.911,5.869-67.2a1.709,1.709,0,0,1,1.252-.545h9.86a1.678,1.678,0,0,1,1.678,1.678,1.678,1.678,0,0,1-.011.191l-.04.353c-.1.863.244,1.5-.7,1.515H13.458c-.518-.019-1.3-.119-1.3.348,0,.49.713.287,1.3.324.031,0,1.319.006,1.351.006h4.013a.855.855,0,0,0,.675-.33l5.4-5.955a1.709,1.709,0,0,1,2.189-.44l.27.152a1.55,1.55,0,0,1,.589,2.111,1.551,1.551,0,0,1-.12.182l-7.241,9.446a1.709,1.709,0,0,1-1.357.669H9.3a1.709,1.709,0,0,0-1.3.6l-.833.973a1.709,1.709,0,0,1-2.41.188q-.054-.046-.1-.1L.985-59.545A1.709,1.709,0,0,1,.949-61.911Z" transform="translate(0.654 71.368)" fill="none" stroke="#565A5C" stroke-width="2" fill-rule="evenodd"/>
          </svg>
        </d2l-icon-custom>
        <div>${this.localize('app-footer.help.text')} <app-link d2l-link href="/contact-support">${this.localize('app-footer.help.link')}</app-link></div>
      </div>
    `;
  }

  get _copyrightTemplate() {
    return html`
      <div class="copyright-container d2l-body-small">
        ${this.localize('app-footer.trademark.pre.text')} <a class="d2l-link" href="https://D2L.com/trademarks" rel="noopener" target="_blank" hreflang="en-ca">D2L.com/trademarks</a> ${this.localize('app-footer.trademark.post.text')}
      </div>
    `;
  }

  get _programPolicyTemplate() {
    return this._programPolicy?.startsWith?.('http') ? html`
      <div class="program-policy d2l-body-small">
        <d2l-icon class="inline-icon" icon="tier3:quizzing"></d2l-icon>
        <div>${this.localize('app-footer.policy.text')} <a class="d2l-link" href="${this._programPolicy}" target="_blank" rel="noopener">${this.localize('app-footer.policy.link')}</a></div>
      </div>
    ` : nothing;
  }
}

window.customElements.define('app-footer', AppFooter);
