import { css, html, LitElement } from 'lit';

import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '../get-started/get-started.js';
import '../onboarding-personalization-flow/onboarding-personalization-flow.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewOnboarding extends LocalizeNova(RequesterMixin(nav(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _selectedJobTitleId: { type: String },
      _selectedJobTitleName: { type: String },
      _selectedJobTitleSOC: { type: String },
      _skillProfile: { type: Object },
      _generatingResults: { state: true },
      _step: { type: String },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        .loading-spinner-container {
          display: flex;
          height: 50vh;
        }

        .loading-spinner {
          margin: auto;
        }
`,
    ];
  }

  constructor() {
    super();
    this._generatingResults = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._selectedJobTitleId = this.session?.user.getSetting('selectedTitleId');
    this._selectedJobTitleName = this.session?.user.getSetting('selectedTitleName');
    this._selectedJobTitleSOC = this.session?.user.getSetting('selectedTitleSOC');

    const { skillCategories, titles } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);

    this._skillProfile = {
      skillCategories: skillCategories,
      titles: titles,
    };

    const onboardingState = localStorage.getItem('onboarding-state');

    if (onboardingState && this._selectedJobTitleId) {
      this._step = onboardingState;
    } else {
      this._step = 'role';
    }
  }

  get getStartedTemplate() {
    return html`<get-started .jobTitleId=${this._selectedJobTitleId} .jobTitleName=${this._selectedJobTitleName} .jobTitleSOC=${this._selectedJobTitleSOC} @get-started-submitted=${this._getStartedSubmitted}></get-started>`;
  }

  _getStartedSubmitted(e) {
    localStorage.setItem('onboarding-state', 'goals');
    this._step = 'goals';
    this._selectedJobTitleId = e.detail.userSelectedJobTitleId;
    this._selectedJobTitleName = e.detail.userSelectedJobTitleName;
    this._selectedJobTitleSOC = e.detail.userSelectedJobTitleSOC;
  }

  get onboardingTemplate() {
    return html`<onboarding-personalization-flow .jobTitleId=${this._selectedJobTitleId} .jobTitleName=${this._selectedJobTitleName} .selectionForProfile=${this._skillProfile} @go-to-get-started=${this.goToGetStarted} @go-to-generating-results=${this.goToGeneratingResults}></onboarding-personalization-flow>`;
  }

  goToGetStarted(e) {
    localStorage.setItem('onboarding-state', 'role');
    this._step = 'role';
    this._skillProfile = e.detail;
  }

  async goToGeneratingResults(e) {
    this._step = 'results';

    const selectedSkillCategories = e.detail.selectedSkillSubcategories;
    const selectedSkills = e.detail.selectedSkills;
    const selectedTitles = e.detail.selectedOtherTitles;

    if (this._selectedJobTitleId) {
      await this.client.setUserSetting('selectedTitleId', this._selectedJobTitleId);
    }
    if (this._selectedJobTitleName) {
      await this.client.setUserSetting('selectedTitleName', this._selectedJobTitleName);
    }
    if (this._selectedJobTitleSOC) {
      await this.client.setUserSetting('selectedTitleSOC', this._selectedJobTitleSOC);
    }
    await this.client.setUser(this.session.user);

    if (this.session.needsOnboarding) {

      // fist time onboaridng
      this.session.user.flaggedTitles = e.detail.selectedOtherTitles.map(title => {
        return { jobId: title.id, jobName: title.name };
      });
      await this.client.saveSkillProfile({ skills: e.detail.selectedSkills.map(skill => skill.id), skillCategories: e.detail.selectedSkillSubcategories, titles: e.detail.selectedOtherTitles });
    } else {
      const { skillCategories, skills } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);

      const profileSkillCategoryIds = skillCategories.map(cat => cat.skillCategoryId);
      const selectedSkillCategoryIds = selectedSkillCategories.map(cat => cat.skillCategoryId);

      const removedIds = profileSkillCategoryIds.filter(id => !selectedSkillCategoryIds.includes(id));
      const addedIds = selectedSkillCategoryIds.filter(id => !profileSkillCategoryIds.includes(id));

      const removedSkillCategories = skillCategories.filter(category => removedIds.includes(category.skillCategoryId));
      const addedSkillCategories = selectedSkillCategories.filter(category => addedIds.includes(category.skillCategoryId));

      const removedSkills = skills.filter(skill => removedIds.includes(skill.skill.subcategory.id));

      await this.client.updateSkillProfile(addedSkillCategories, removedSkillCategories, selectedSkills.map(skill => skill.id), removedSkills.map(skill => skill.skillId), selectedTitles);
    }

    const selectedTitleChangedEvent = new CustomEvent('nova-selected-title-changed', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(selectedTitleChangedEvent);

    this.navigateWithoutHistory('/');
  }

  get _generatingResultsHtml() {
    // todo: switch this with an animation
    return html`
      <div>Generating results...</div>
    `;
  }

  get _loadingSpinner() {
    return html`
      <div class="loading-spinner-container">
        <d2l-loading-spinner class="loading-spinner" size="50"></d2l-loading-spinner>
      </div>
    `;
  }

  render() {
    if (this._step === 'results') return this._generatingResultsHtml;
    if (this._step && this._step === 'goals') return this.onboardingTemplate;
    if (this._step && this._step === 'role') return this.getStartedTemplate;
    return this._loadingSpinner;
  }
}

window.customElements.define('view-onboarding', ViewOnboarding);
