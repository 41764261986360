import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '../../../components/general/nova-card/nova-card.js';
import '../../../components/users/profile-image/profile-image.js';
import { heading2Styles, heading3Styles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import TenantSSO from '../../../../../shared/models/tenant/sso.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { novaCardStyles } from '../../general/nova-card/nova-card-styles.js';

export default class LoginMulti extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
    };
  }

  static get styles() {
    return [
      novaCardStyles,
      heading2Styles,
      heading3Styles,
      heading4Styles,
      css`
        :host {
          display: block;
        }

        .welcome-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          z-index: 1;
        }

        .welcome-text {
          color: #ffffff;
          margin-right: 130px;
        }

        .login-message {
          min-width: 370px;
        }

        .sso-name {
          align-items: center;
          display: flex;
        }

        @media (max-width: 960px) {
          .welcome-content {
            justify-content: center;
          }

          .welcome-text {
            margin-right: 0;
          }
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._ssoConfig = this.session.appDetails.ssoConfig.filter(sso => sso.loginType === 'saml').map(sso => new TenantSSO(sso));

  }

  render() {
    return html`
      <div class="welcome-content">
        <div class="welcome-text">
          <img alt="client-logo" height="100px" src="../../../../assets/img/d2l-wave-knockout.svg">
          <h1 class="login-header">${this.localize('login.marketing.form1.introTextHeader')}</h1>
        </div>
        <nova-card class="login-message">
          <h1 slot="header" class="d2l-heading-1">${this.localize('login.multi.header')}</h1>
          <span slot="header" class="d2l-body">${this.localize('login.multi.subHeader')}</span>
          <div slot="primary">
            ${this._loginListTemplate()}
          </div>
        </nova-card>
      </div>
    `;
  }

  _loginListTemplate() {
    return html`
        <d2l-list>
        ${this._ssoConfig.map(sso => html`
          <d2l-list-item href="${this.session.getLoginUrl(sso.id)}">
            <profile-image slot="illustration" illustration .user="${sso}" meta-attribute="${sso.department}"></profile-image>
            <d2l-list-item-content class="sso-name">
              ${sso.name}
            </d2l-list-item-content>
          </d2l-list-item>
        `)}
        </d2l-list>
    `;
  }

}

window.customElements.define('login-multi', LoginMulti);
